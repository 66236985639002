import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'app/shared/common.service';

@Component({
  selector: 'app-tco-fleet-admin-detail',
  templateUrl: './tco-fleet-admin-detail.component.html',
  styleUrls: ['./tco-fleet-admin-detail.component.scss'],
})
export class TcoFleetAdminDetailComponent {
  fleetAdminId;
  fleetAdminDetails;
  constructor(private commonService: CommonService, private route: ActivatedRoute) {}

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      const id = params.get('fleetAdminId');
      this.fleetAdminId = id;
    });
    this.commonService.fetchUserById(this.fleetAdminId).subscribe({
      next: data => {
        this.fleetAdminDetails = data;
      },
      error: error => {
        console.log('Failed to fetch organisation by Id.', error);
      },
    });
  }
}
