<div class="flex flex-col flex-auto min-w-0">
  <!-- Main -->
  <div class="flex-auto p-6 sm:p-10">
    <!-- CONTENT GOES HERE -->
    <div class="">
      <div class="grid grid-cols-1 gap-4">
        <div class="grid grid-cols-1 gap-4">
          <fuse-card class="m-6 sm:block">
            <div class="grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-1 m-4 grid-cols-1 gap-4">
              <div class="md:ml-6 mb-8 md:mb-4 mt-4 rounded-lg overflow-hidden">
                <img
                  *ngIf="organisationDetails.logoPath"
                  src="{{ organisationDetails?.logoPath }}"
                  alt="Logo"
                  class="w-full h-60 object-cover"
                />
                <div *ngIf="!organisationDetails.logoPath" class="ml-2 w-full h-60 object-cover">
                  <ngx-avatar
                    size="240"
                    cornerRadius="10"
                    [round]="false"
                    name="{{ organisationDetails.name }}"
                  ></ngx-avatar>
                </div>
              </div>
              <!-- Org Details -->
              <div class="mb-8 md:mb-4 mt-4">
                <h1 class="text-3xl font-bold">Detail</h1>
                <div class="flex">
                  <div class="flex-none w-36">
                    <span class="text-xl text-primary-500">name:</span>
                  </div>
                  <div class="flex-auto w-64">
                    <span class="text-lg text-secondary">{{ organisationDetails?.name }}</span>
                  </div>
                </div>
                <div class="flex">
                  <div class="flex-none w-36">
                    <span class="text-xl text-primary-500">vat:</span>
                  </div>
                  <div class="flex-auto w-64">
                    <span class="text-lg text-secondary"> {{ organisationDetails?.vat }}</span>
                  </div>
                </div>
                <div class="flex">
                  <div class="flex-none w-36">
                    <span class="text-xl text-primary-500">country:</span>
                  </div>
                  <div class="flex-auto w-64">
                    <span class="text-lg text-secondary">{{ organisationDetails?.country.name }} </span>
                  </div>
                </div>
              </div>
              <!-- Address Info -->
              <div class="mb-8 md:mb-4 mt-4">
                <h1 class="text-3xl font-bold">Address Info</h1>
                <div class="flex">
                  <div class="flex-none w-36">
                    <span class="text-xl text-primary-500">address:</span>
                  </div>
                  <div class="flex-auto w-64">
                    <span class="text-lg text-secondary">{{ organisationDetails?.address }}</span>
                  </div>
                </div>
                <div class="flex">
                  <div class="flex-none w-36">
                    <span class="text-xl text-primary-500">postal code:</span>
                  </div>
                  <div class="flex-auto w-64">
                    <span class="text-lg text-secondary">{{ organisationDetails?.postalCode }}</span>
                  </div>
                </div>

                <div class="flex">
                  <div class="flex-none w-36">
                    <span class="text-xl text-primary-500">city:</span>
                  </div>
                  <div class="flex-auto w-64">
                    <span class="text-lg text-secondary">{{ organisationDetails?.city }}</span>
                  </div>
                </div>
                <div class="flex">
                  <div class="flex-none w-36">
                    <span class="text-xl text-primary-500">state:</span>
                  </div>
                  <div class="flex-auto w-64">
                    <span class="text-lg text-secondary">{{ organisationDetails?.state }}</span>
                  </div>
                </div>
                <div class="flex">
                  <div class="flex-none w-36">
                    <span class="text-xl text-primary-500">region:</span>
                  </div>
                  <div class="flex-auto w-64">
                    <span class="text-lg text-secondary">{{ organisationDetails?.regionBe }}</span>
                  </div>
                </div>
              </div>
            </div>
          </fuse-card>
        </div>
      </div>
      <div class="grid grid-cols-1 gap-4">
        <app-admin-list [organisation]="organisationDetails"></app-admin-list>
      </div>
    </div>
  </div>
</div>
