<mat-list>
  <mat-list-item class="p-0">
    <div class="flex justify-between">
      <div mat-dialog-title class="text-2xl text-primary items-center flex justify-center font-bold">
        <span>Attach Organisation</span>
      </div>
      <button mat-icon-button class="close-button" [mat-dialog-close]="true" (click)="onCloseDialog()">
        <mat-icon class="close-icon" color="warn">close</mat-icon>
      </button>
    </div>
  </mat-list-item>
  <mat-divider></mat-divider>
</mat-list>
<mat-dialog-content class="mat-typography">
  <form [formGroup]="orgForm">
    <mat-list class="flex">
      <mat-list-item class="p-0">
        <h1 class="text-2xl text-primary">Admin</h1>
      </mat-list-item>
      <mat-list-item class="p-0">
        <h1 class="text-2xl text-primary">{{ data?.currentUser.email }}</h1>
      </mat-list-item>
      <mat-divider></mat-divider>
    </mat-list>
    <div class="grid grid-cols-1 gap-4">
      <div class="font-medium text-primary mb-1">Admins <span class="text-red-500">*</span></div>
      <mat-form-field class="w-full">
        <mat-select placeholder="select Option" formControlName="attachOrgnisation">
          <mat-option value="option" *ngFor="let org of this.orgList" [value]="org">{{ org.name }}</mat-option>
        </mat-select>
        <mat-error *ngIf="orgForm?.get('attachOrgnisation').hasError('required')">
          please select Organisation
        </mat-error>
      </mat-form-field>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button
    mat-raised-button
    class="capitalize"
    color="primary"
    [mat-dialog-close]="true"
    cdkFocusInitial
    (click)="attachOrganisationToUser()"
    [disabled]="orgForm.invalid"
  >
    <span> Attach Organisation </span>
  </button>
</mat-dialog-actions>
