<div class="flex flex-col flex-auto min-w-0">
  <!-- Main -->
  <div class="flex-auto p-6 sm:p-10">
    <!-- CONTENT GOES HERE -->
    <div>
      <div class="grid grid-cols-1 gap-4 rounded-lg text-primary-500 bg-white p-4">
        <h1 class="text-3xl break-words">Service Provider Details:</h1>
        <div class="flex">
          <div class="flex-none lg:w-36 md:w-36 sm:w-18 w-18">
            <span class="text-xl text-primary-500">ID</span>
          </div>
          <div class="flex-auto w-64 break-all">
            <span class="text-lg text-secondary">{{ serviceProviderDetails?.id }}</span>
          </div>
        </div>
        <mat-divider></mat-divider>
        <div class="flex">
          <div class="flex-none lg:w-36 md:w-36 sm:w-18 w-18">
            <span class="text-xl text-primary-500">Logo</span>
          </div>
          <div class="flex-auto w-64 break-all">
            <span class="text-lg text-secondary">
              <img
                *ngIf="serviceProviderDetails?.logo"
                class="rounded-full p-2"
                src="{{ serviceProviderDetails?.logo }}"
              />
              <div class="ml-2">
                <ngx-avatar
                  size="35"
                  *ngIf="!serviceProviderDetails?.logo"
                  name="{{ serviceProviderDetails?.name }}"
                ></ngx-avatar>
              </div>
            </span>
          </div>
        </div>
        <mat-divider></mat-divider>
        <div class="flex">
          <div class="flex-none lg:w-36 md:w-36 sm:w-18 w-18">
            <span class="text-xl text-primary-500">Name</span>
          </div>
          <div class="flex-auto w-64 break-all">
            <span class="text-lg text-secondary">{{ serviceProviderDetails?.name }}</span>
          </div>
        </div>
        <mat-divider></mat-divider>
        <div class="flex">
          <div class="flex-none lg:w-36 md:w-36 sm:w-18 w-18">
            <span class="text-xl text-primary-500">Email</span>
          </div>
          <div class="flex-auto w-64 break-all">
            <span class="text-lg text-secondary">{{ serviceProviderDetails?.email }}</span>
          </div>
        </div>
        <mat-divider></mat-divider>
        <div class="flex">
          <div class="flex-none lg:w-36 md:w-36 sm:w-18 w-18">
            <span class="text-xl text-primary-500">Telephone</span>
          </div>
          <div class="flex-auto w-64 break-all">
            <span class="text-lg text-secondary">{{ serviceProviderDetails?.phone }}</span>
          </div>
        </div>
        <mat-divider></mat-divider>
        <div class="flex">
          <div class="flex-none lg:w-36 md:w-36 sm:w-18 w-18">
            <span class="text-xl text-primary-500">Address</span>
          </div>
          <div class="flex-auto w-64 break-all">
            <span class="text-lg text-secondary">{{ serviceProviderDetails?.address }}</span>
          </div>
        </div>
        <mat-divider></mat-divider>
        <div class="flex">
          <div class="flex-none lg:w-36 md:w-36 sm:w-18 w-18">
            <span class="text-xl text-primary-500">Postal Code</span>
          </div>
          <div class="flex-auto w-64 break-all">
            <span class="text-lg text-secondary">{{ serviceProviderDetails?.postalCode }}</span>
          </div>
        </div>
        <mat-divider></mat-divider>
        <div class="flex">
          <div class="flex-none lg:w-36 md:w-36 sm:w-18 w-18">
            <span class="text-xl text-primary-500">City</span>
          </div>
          <div class="flex-auto w-64 break-all">
            <span class="text-lg text-secondary">{{ serviceProviderDetails?.city }}</span>
          </div>
        </div>
        <mat-divider></mat-divider>
        <div class="flex">
          <div class="flex-none lg:w-36 md:w-36 sm:w-18 w-18">
            <span class="text-xl text-primary-500">State</span>
          </div>
          <div class="flex-auto w-64 break-all">
            <span class="text-lg text-secondary">{{ serviceProviderDetails?.state }}</span>
          </div>
        </div>
        <mat-divider></mat-divider>
        <div class="flex">
          <div class="flex-none lg:w-36 md:w-36 sm:w-18 w-18">
            <span class="text-xl text-primary-500">Country</span>
          </div>
          <div class="flex-auto w-64 break-all">
            <span class="text-lg text-secondary">{{ serviceProviderDetails?.country?.name }}</span>
          </div>
        </div>
      </div>

      <div class="grid grid-cols-1 gap-4 rounded-lg text-primary-500 bg-white mt-4 p-4">
        <h1 class="text-3xl break-words">Asset Types</h1>
        <div class="flex">
          <div class="flex-none lg:w-36 md:w-36 sm:w-18 w-18">
            <span class="text-xl text-primary-500">Car Assets</span>
          </div>
          <div class="flex-auto w-64 break-all" [ngSwitch]="serviceProviderDetails?.isCarAssetProvider">
            <mat-icon color="primary" *ngSwitchCase="true" svgIcon="heroicons_outline:check-circle"></mat-icon>
            <mat-icon color="warn" *ngSwitchCase="false" svgIcon="heroicons_outline:x-circle"></mat-icon>
          </div>
        </div>
        <mat-divider></mat-divider>
        <div class="flex">
          <div class="flex-none lg:w-36 md:w-36 sm:w-18 w-18">
            <span class="text-xl text-primary-500">Fuel Card Assets</span>
          </div>
          <div class="flex-auto w-64 break-all" [ngSwitch]="serviceProviderDetails?.isFuelCardAssetProvider">
            <mat-icon color="primary" *ngSwitchCase="true" svgIcon="heroicons_outline:check-circle"></mat-icon>
            <mat-icon color="warn" *ngSwitchCase="false" svgIcon="heroicons_outline:x-circle"></mat-icon>
          </div>
        </div>
        <mat-divider></mat-divider>
        <div class="flex">
          <div class="flex-none lg:w-36 md:w-36 sm:w-18 w-18">
            <span class="text-xl text-primary-500">Bicycle Assets</span>
          </div>
          <div class="flex-auto w-64 break-all" [ngSwitch]="serviceProviderDetails?.isBicycleAssetProvider">
            <mat-icon color="primary" *ngSwitchCase="true" svgIcon="heroicons_outline:check-circle"></mat-icon>
            <mat-icon color="warn" *ngSwitchCase="false" svgIcon="heroicons_outline:x-circle"></mat-icon>
          </div>
        </div>
        <mat-divider></mat-divider>
        <div class="flex">
          <div class="flex-none lg:w-36 md:w-36 sm:w-18 w-18">
            <span class="text-xl text-primary-500">Charging System Assets</span>
          </div>
          <div class="flex-auto w-64 break-all" [ngSwitch]="serviceProviderDetails?.isChargingSystemAssetProvider">
            <mat-icon color="primary" *ngSwitchCase="true" svgIcon="heroicons_outline:check-circle"></mat-icon>
            <mat-icon color="warn" *ngSwitchCase="false" svgIcon="heroicons_outline:x-circle"></mat-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
