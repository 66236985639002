<div class="flex flex-col flex-auto min-w-0">
  <!-- Main -->
  <div class="flex-auto p-6 sm:p-10">
    <!-- CONTENT GOES HERE -->

    <div class="flex flex-col mx-6 mt-3 rounded-md space-y-4">
      <div class="flex justify-start space-x-2">
        <button mat-raised-button class="capitalize" color="primary" (click)="onImportClick()">
          <mat-icon svgIcon="mat_outline:upload_file"></mat-icon>
          <span class="ml-2">Import new data</span>
        </button>

        <button mat-raised-button class="capitalize" color="primary" disabled (click)="onExportClick()">
          <mat-icon svgIcon="mat_outline:file_download"></mat-icon>
          <span class="ml-2">Export data</span>
        </button>
      </div>

      <div>
        <mat-form-field class="w-full">
          <mat-select
            placeholder="Select brand..."
            [(value)]="selectedBrand"
            (selectionChange)="selectBrand($event)"
            [disabled]="!this.brands.length"
          >
            <mat-option value="option" *ngFor="let brand of this.brands" [value]="brand">{{ brand }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="w-full">
          <mat-select
            placeholder="Select model..."
            [(value)]="selectedModel"
            (selectionChange)="selectModel($event)"
            [disabled]="!this.models.length"
          >
            <mat-option value="option" *ngFor="let model of this.models" [value]="model">{{ model }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="w-full">
          <mat-select
            placeholder="Select type..."
            [(value)]="selectedType"
            (selectionChange)="selectType($event)"
            [disabled]="!this.types.length"
          >
            <mat-option value="option" *ngFor="let typeName of this.types" [value]="typeName">{{
              typeName
            }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div *ngIf="this.carModelInfo">
        <div class="flex flex-row">
          <div class="w-48 font-semibold">Brand:</div>
          <div class="value">{{ carModelInfo.brand }}</div>
        </div>
        <div class="flex flex-row">
          <div class="w-48 font-semibold">Model:</div>
          <div class="value">{{ carModelInfo.model }}</div>
        </div>
        <div class="flex flex-row">
          <div class="w-48 font-semibold">Name:</div>
          <div class="value">{{ carModelInfo.name }}</div>
        </div>
        <div class="flex flex-row">
          <div class="w-48 font-semibold">Full name:</div>
          <div class="value">{{ carModelInfo.fullName }}</div>
        </div>
        <div class="flex flex-row">
          <div class="w-48 font-semibold">Fuel type:</div>
          <div class="value">{{ FUEL_TYPES[carModelInfo.fuelType] }}</div>
        </div>
        <div class="flex flex-row">
          <div class="w-48 font-semibold">Mass:</div>
          <div class="value">{{ carModelInfo.mass }} kg</div>
        </div>
        <div class="flex flex-row">
          <div class="w-48 font-semibold">Engine power:</div>
          <div class="value">{{ carModelInfo.enginePower }} kW</div>
        </div>
        <div class="flex flex-row">
          <div class="w-48 font-semibold">Fiscal HP:</div>
          <div class="value">{{ carModelInfo.fiscalHorsepower }}</div>
        </div>
        <div class="flex flex-row">
          <div class="w-48 font-semibold">Euronorm:</div>
          <div class="value">{{ carModelInfo.euronorm }}</div>
        </div>
        <div class="flex flex-row">
          <div class="w-48 font-semibold">CO2 (NEDC):</div>
          <div class="value">{{ carModelInfo.CO2EmissionNEDC }}</div>
        </div>
        <div class="flex flex-row">
          <div class="w-48 font-semibold">CO2 (WLTP):</div>
          <div class="value">{{ carModelInfo.CO2EmissionWLTP }}</div>
        </div>
        <div class="flex flex-row">
          <div class="w-48 font-semibold">Battery capacity:</div>
          <div class="value">{{ carModelInfo.batteryCapacity }} kWh</div>
        </div>
        <div class="flex flex-row">
          <div class="w-48 font-semibold">Catalogue price:</div>
          <div class="value">{{ carModelInfo.cataloguePrice }}</div>
        </div>
        <div class="flex flex-row">
          <div class="w-48 font-semibold">Electricity consumption:</div>
          <div class="value">{{ carModelInfo.electricityConsumption }}</div>
        </div>
        <div class="flex flex-row">
          <div class="w-48 font-semibold">Fuel Consumption:</div>
          <div class="value">{{ carModelInfo.fuelConsumption }}</div>
        </div>
      </div>
    </div>
  </div>
</div>
