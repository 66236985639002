import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { User } from 'app/Models/user';
import { CommonService } from 'app/shared/common.service';
import { TRASHED } from 'app/shared/enum.trashed';
import { TcoFleetAdminEditDialogComponent } from '../tco-fleet-admin-edit-dialog/tco-fleet-admin-edit-dialog.component';
import { TcoFleetAdminDialogComponent } from '../tco-fleet-admin-dialog/tco-fleet-admin-dialog.component';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { UserService } from 'app/shared/user.service';
import { MatPaginator } from '@angular/material/paginator';
import { map, merge, startWith, switchMap } from 'rxjs';
import { MatSort, SortDirection } from '@angular/material/sort';
import { FormControl } from '@angular/forms';
@Component({
  selector: 'app-tco-fleet-admins',
  templateUrl: './tco-fleet-admins.component.html',
  styleUrls: ['./tco-fleet-admins.component.scss'],
})
export class TcoFleetAdminsComponent {
  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  totalData;
  lastPage;
  pageSizes = [5, 10];
  searchValid;
  dataSource: MatTableDataSource<User>;
  searchKeywordFilter = new FormControl();
  displayedColumns: string[] = ['FIRST_NAME', 'LAST_NAME', 'email', 'phoneNumber', 'cognitoId', 'actions'];
  constructor(
    private commonService: CommonService,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private router: Router,
    private _userService: UserService,
  ) {
    this.dataSource = new MatTableDataSource<User>([]);
    this._userService.fetchUserData().subscribe(fetchResult => {
      this.commonService.currentLoginUserId = fetchResult.data.me.id;
    });
  }

  ngOnInit() {}

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    this.searchKeywordFilter.valueChanges.subscribe({
      next: () => {
        this.searchValid = this.searchKeywordFilter.value.length >= 3 ? true : false;
        this.paginator.pageIndex = 0;
      },
    });
    merge(this.searchKeywordFilter.valueChanges, this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          var filterValue =
            this.searchKeywordFilter.value == null || !this.searchValid ? '' : this.searchKeywordFilter.value;
          return this.getFleetAdmins(
            this.paginator.pageIndex + 1,
            this.paginator.pageSize,
            this.sort.active,
            this.sort.direction,
            filterValue,
          );
        }),
        map(users => {
          if (users == null) return [];
          this.totalData = users.paginatorInfo.total;
          this.lastPage - users.paginatorInfo.lastPage;
          return users.data;
        }),
      )
      .subscribe({
        next: usersData => {
          this.dataSource = new MatTableDataSource(usersData);
        },
        error: error => {
          console.log('Error fetch AllAdmins.', error);
        },
      });
  }
  getFleetAdmins(pageNumber: Number, pageSize: Number, sort: string, orderBy: SortDirection, filter: string) {
    return this.commonService.fetchFleetAdmins(pageNumber, pageSize, sort, orderBy, filter, TRASHED.TRASHED_WITHOUT);
  }
  openDialog() {
    const dialogRef = this.dialog.open(TcoFleetAdminDialogComponent, {
      disableClose: true,
      panelClass: 'full-screen-modal',
      width: '40vw',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.paginator.pageIndex = 0;
        this.getFleetAdmins(
          this.paginator.pageIndex + 1,
          this.paginator.pageSize,
          this.sort.active,
          this.sort.direction,
          '',
        ).subscribe({
          next: fleetAdminData => {
            this.paginator.length = this.paginator.length + 1;
            this.dataSource = new MatTableDataSource(fleetAdminData.data);
          },
          error: error => {
            console.log('Error while refetch tco fleet admins Data.', error);
          },
        });
      }
    });
  }
  viewFleetAdminDetail(fleetAdminId) {
    this.router.navigate(['/fleetadmin', fleetAdminId]);
  }
  editFleetAdmin(index, fleetAdmin) {
    const dialogRef = this.dialog.open(TcoFleetAdminEditDialogComponent, {
      disableClose: true,
      panelClass: 'full-screen-modal',
      width: '75vw',
      data: fleetAdmin,
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getFleetAdmins(
          this.paginator.pageIndex + 1,
          this.paginator.pageSize,
          this.sort.active,
          this.sort.direction,
          '',
        ).subscribe({
          next: tcoFleetAdminsData => {
            this.dataSource = new MatTableDataSource(tcoFleetAdminsData.data);
          },
          error: error => {
            console.log('Error while refetch tco fleet admins Data.', error);
          },
        });
      }
    });
  }
  deleteFleetAdmin(fleetAdminId) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: true,
      data: { actionType: 'deleteFleetAdmin', id: fleetAdminId },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.paginator.length = this.paginator.length - 1;
        this.paginator.pageIndex = 0;
        this.getFleetAdmins(
          this.paginator.pageIndex + 1,
          this.paginator.pageSize,
          this.sort.active,
          this.sort.direction,
          '',
        ).subscribe({
          next: tcoFleetAdminsData => {
            this.dataSource = new MatTableDataSource(tcoFleetAdminsData.data);
          },
          error: error => {
            console.log('Error while refetch tco fleet admins Data.', error);
          },
        });
      }
    });
  }

  isRowDisabled(row: any): boolean {
    if (this.commonService.currentLoginUserId == row.id) {
      return true;
    }
    return false;
    // Return true or false based on your condition
  }
}
