import { gql } from 'apollo-angular';
export const CREATE_ORGANISATION_ADMIN = gql`
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      id
    }
  }
`;
export const UPDATE_ORGANISATION_ADMIN = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
      email
      firstName
      lastName
      phoneNumber
    }
  }
`;

export const FETCH_USER_BY_ID = gql`
  query userById($id: ID!) {
    userById(id: $id) {
      id
      firstName
      lastName
      email
      phoneNumber
      cognitoId
      companies {
        id
        isOrganisation
      }
      organisation {
        id
        name
        createdAt
      }
    }
  }
`;
export const DELETE_USER_BY_ID = gql`
  mutation deleteUser($id: ID!) {
    deleteUser(id: $id) {
      id
      email
      firstName
      lastName
      phoneNumber
      organisation {
        id
        name
        createdAt
      }
    }
  }
`;
