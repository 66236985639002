import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UploadFileDialogComponent } from '../upload-file-dialog/upload-file-dialog.component';
import { Apollo } from 'apollo-angular';
import {
  carModelInfoBrandsQuery,
  carModelInfoModelsQuery,
  carModelInfoNamesQuery,
  carModelInfoQuery,
} from 'app/graphQL/carModelInfo';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-car-model-info',
  templateUrl: './car-model-info.component.html',
})
export class CarModelInfoComponent {
  public selectedBrand: string | null = null;
  public selectedModel: string | null = null;
  public selectedType: string | null = null;

  public brands: string[] = [];
  public models: string[] = [];
  public types: string[] = [];

  public carModelInfo: any = null;

  public FUEL_TYPES = [
    '',
    'Petrol',
    'Diesel',
    'LPG',
    'Electricity',
    'Other',
    'CNG',
    'Hybrid Petrol',
    'Hybrid Diesel',
    'PHEV Petrol',
    'PHEV Diesel',
  ];

  private requestCounter = 0;

  constructor(private apollo: Apollo, public dialog: MatDialog) {
    this.getBrands();
  }

  private getBrands() {
    this.apollo.query({ query: carModelInfoBrandsQuery }).subscribe(data => {
      this.brands = (data.data as any).carModelInfoBrands;
    });
  }

  private getModels() {
    const t = ++this.requestCounter;
    this.apollo.query({ query: carModelInfoModelsQuery, variables: { brand: this.selectedBrand } }).subscribe(data => {
      // only overwrite results if this was the last request we issued
      if (this.requestCounter == t) {
        this.models = (data.data as any).carModelInfoModels;
      }
    });
  }

  private clearModels() {
    this.models = [];
    this.selectedModel = null;
  }

  private getTypes() {
    const t = ++this.requestCounter;
    this.apollo
      .query({ query: carModelInfoNamesQuery, variables: { brand: this.selectedBrand, model: this.selectedModel } })
      .subscribe(data => {
        // only overwrite results if this was the last request we issued
        if (this.requestCounter == t) {
          this.types = (data.data as any).carModelInfoNames;
        }
      });
  }

  private clearTypes() {
    this.types = [];
    this.selectedType = null;
  }

  private getCarModelInfo() {
    const t = ++this.requestCounter;
    this.apollo
      .query({
        query: carModelInfoQuery,
        variables: { brand: this.selectedBrand, model: this.selectedModel, name: this.selectedType },
      })
      .subscribe(data => {
        // only overwrite results if this was the last request we issued
        if (this.requestCounter == t) {
          this.carModelInfo = (data.data as any).carModelInfo;
        }
      });
  }

  private clearCarModelInfo() {
    this.carModelInfo = null;
  }

  public selectBrand(event: MatSelectChange) {
    this.clearModels();
    this.clearTypes();
    this.clearCarModelInfo();
    this.getModels();
  }

  public selectModel(event: MatSelectChange) {
    this.clearTypes();
    this.clearCarModelInfo();
    this.getTypes();
  }

  public selectType(event: MatSelectChange) {
    this.clearCarModelInfo();
    this.getCarModelInfo();
  }

  ngOnInit(): void {}

  onImportClick() {
    this.dialog.open(UploadFileDialogComponent);
  }

  onExportClick() {}
}
