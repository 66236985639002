import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { Country } from 'app/Models/country';
import { User } from 'app/Models/user';
import { CommonService } from 'app/shared/common.service';
import { TRASHED } from 'app/shared/enum.trashed';
import { ROLES } from 'app/shared/roles.enum';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';

@Component({
  selector: 'app-adddialog',
  templateUrl: './adddialog.component.html',
  styleUrls: ['./adddialog.component.scss'],
})
export class AdddialogComponent {
  form: FormGroup;
  adminForm: FormGroup;
  countries: Country[] = [];
  adminList: User[] = [];
  orgRoleId;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  uploadImage;
  isBE: boolean = false;
  constructor(
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private _snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<AdddialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private sanitizer: DomSanitizer,
  ) {}
  ngOnInit() {
    this.form = this.formBuilder.group({
      orgName: ['', Validators.required],
      vat: ['', Validators.required],
      country: ['', Validators.required],
      address: ['', Validators.required],
      postalCode: ['', Validators.required],
      city: ['', Validators.required],
      state: ['', Validators.required],
      region: [''],
      adminName: ['', Validators.required],
      adminLastName: ['', Validators.required],
      adminEmail: ['', [Validators.email, Validators.required]],
      adminPhone: ['', Validators.required],
      enableOrderFeature: [false, Validators.required],
    });
    this.commonService.fetchRole().subscribe(data => {
      let role = data.find(role => role.slug == ROLES.ORGANISATION_ADMIN);
      this.orgRoleId = role?.id;
    });
    if (this.data?.dialogType == 'attachOrgAdmin') {
      this.adminForm = this.formBuilder.group({
        attachAdmin: ['', Validators.required],
      });
      this.commonService.fetchAllAdmins(TRASHED.TRASHED_WITHOUT, 1, 1000, 'FIRST_NAME', 'ASC', '').subscribe({
        next: adminsDetails => {
          this.adminList = adminsDetails.data.filter(user => user?.organisation?.id != this.data?.org?.id);
        },
        error: error => {
          console.log('Error while fetch All Admins.', error);
        },
      });
    }
  }
  /**
   * The function closes a dialog and returns a form.
   */
  onCloseDialog(dialogType) {
    this.dialogRef.close();
  }
  /**
   * The function adds a new organization and creates an admin user for that organization.
   */
  addOrganisation() {
    let organisationInput = {
      address: this.form.value.address,
      city: this.form.value.city,
      country: this.form.value.country.id,
      logoPath: '',
      name: this.form.value.orgName,
      postalCode: this.form.value.postalCode,
      regionBe: this.form.value.region,
      state: this.form.value.state,
      vat: this.form.value.vat,
      settings: {
        orderModule: this.form.value.enableOrderFeature,
      },
    };
    if (this.uploadImage) {
      this.onUpload().subscribe({
        next: data => {
          organisationInput.logoPath = data.path;
          this.commonService.addOrganisation(organisationInput).subscribe({
            next: data => {
              let userInput = {
                firstName: this.form.value.adminName,
                lastName: this.form.value.adminLastName,
                email: this.form.value.adminEmail,
                phoneNumber: this.form.value.adminPhone,
                roles: { connect: [this.orgRoleId] },
                organisation: { connect: data?.id },
              };
              this.commonService.createOrganisationAdmin(userInput).subscribe({
                next: ({ data }) => {
                  this._snackBar.open('Organisation created with Admin successfully.', 'close', {
                    horizontalPosition: 'right',
                    verticalPosition: 'top',
                    duration: 1500,
                  });
                  this.dialogRef.close(data);
                },
                error: error => {
                  console.log('Error while create Admin.', error);
                  this._snackBar.open('Failed to create Organisation Admin.', 'close', {
                    horizontalPosition: 'right',
                    verticalPosition: 'top',
                    duration: 1500,
                  });
                },
              });
              this.dialogRef.close(data);
            },
            error: error => {
              console.log('Error while Add Organisation', error);
              this._snackBar.open('Failed To create Organisation.', 'close', {
                horizontalPosition: 'right',
                verticalPosition: 'top',
                duration: 1000,
              });
            },
          });
        },
        error: () => {
          this._snackBar.open('Failed To create Organisation.', 'close', {
            horizontalPosition: 'right',
            verticalPosition: 'top',
            duration: 1000,
          });
        },
      });
    } else {
      this.commonService.addOrganisation(organisationInput).subscribe({
        next: data => {
          let userInput = {
            firstName: this.form.value.adminName,
            lastName: this.form.value.adminLastName,
            email: this.form.value.adminEmail,
            phoneNumber: this.form.value.adminPhone,
            roles: { connect: [this.orgRoleId] },
            organisation: { connect: data?.id },
          };
          this.commonService.createOrganisationAdmin(userInput).subscribe({
            next: ({ data }) => {
              this._snackBar.open('Organisation created with Admin successfully.', 'close', {
                horizontalPosition: 'right',
                verticalPosition: 'top',
                duration: 1500,
              });
              this.dialogRef.close(data);
            },
            error: error => {
              console.log('Error while create Admin.', error);
              this._snackBar.open('Failed to create Organisation Admin.', 'close', {
                horizontalPosition: 'right',
                verticalPosition: 'top',
                duration: 1500,
              });
            },
          });
          this.dialogRef.close(data);
        },
        error: error => {
          console.log('Error while Add Organisation', error);
          this._snackBar.open('Failed To create Organisation.', 'close', {
            horizontalPosition: 'right',
            verticalPosition: 'top',
            duration: 1000,
          });
        },
      });
    }
  }
  attachAdminToOrganisation() {
    let connectObj = this.adminForm.value.attachAdmin ? this.data?.org?.id : null;
    let attachAdminInput = {
      id: this.adminForm.value.attachAdmin.id,
      organisation: {
        connect: connectObj,
      },
    };
    this.commonService.updateUser(attachAdminInput).subscribe({
      next: data => {
        this._snackBar.open('attach admin to Organisation successfully.', 'close', {
          horizontalPosition: 'right',
          verticalPosition: 'top',
          duration: 1500,
        });
        this.dialogRef.close(data);
      },
      error: error => {
        console.log('Error while update User.', error);
        this._snackBar.open('Failed to attach admin to Organisation.', 'close', {
          horizontalPosition: 'right',
          verticalPosition: 'top',
          duration: 1500,
        });
      },
    });
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = this.sanitizer.bypassSecurityTrustUrl(event.objectUrl);
    this.uploadImage = event.blob;
    // event.blob can be used to upload the cropped image
  }

  onUpload() {
    return this.commonService.upload(this.uploadImage);
  }
  changeCountry(country) {
    this.form.get('region').setValue(country.value.name.toLowerCase() == 'belgium' ? this.form.value.region : null);
    this.isBE = country.value.name.toLowerCase() == 'belgium' ? true : false;
  }
}
