<div class="flex flex-col flex-auto min-w-0">
  <!-- Main -->
  <div class="flex-auto p-6 sm:p-10">
    <!-- CONTENT GOES HERE -->
    <div class="h-180 min-h-180">
      <div>
        <div class="flex flex-col mx-6 mt-3 rounded-md">
          <div class="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 grid-cols-1 gap-4">
            <div>
              <button mat-raised-button class="capitalize">
                <input matInput [formControl]="searchKeywordFilter" placeholder="search" #input />
              </button>
            </div>
            <div class="flex justify-end">
              <button mat-raised-button class="capitalize" color="primary" (click)="openDialog()">
                <mat-icon svgIcon="heroicons_outline:user-group"></mat-icon>
                <span class="ml-2">Create TCO Fleet Admin</span>
              </button>
            </div>
          </div>
        </div>
        <div class="grid grid-cols-1 m-6 overflow-y-scroll rounded-lg">
          <div>
            <table
              mat-table
              [dataSource]="dataSource"
              class=""
              matSortActive="CREATED_AT"
              matSortDisableClear
              matSortDirection="desc"
              matSort
              #empTbSort="matSort"
            >
              <!--- Note that these columns can be defined in any order.
                                The actual rendered columns are set as a property on the row definition" -->

              <!-- logo Column -->
              <ng-container matColumnDef="logo">
                <th mat-header-cell *matHeaderCellDef>logo</th>
                <td mat-cell *matCellDef="let row" class="border-0">{{ row.logo }}</td>
              </ng-container>

              <!-- Name Column -->
              <ng-container matColumnDef="FIRST_NAME">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>firstName</th>
                <td mat-cell *matCellDef="let row" class="border-0">{{ row.firstName }}</td>
              </ng-container>

              <!-- Created Column -->
              <ng-container matColumnDef="LAST_NAME">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>lastName</th>
                <td mat-cell *matCellDef="let row" class="border-0">{{ row.lastName }}</td>
              </ng-container>

              <!-- firstname Column -->
              <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef>email</th>
                <td mat-cell *matCellDef="let row" class="border-0">{{ row.email }}</td>
              </ng-container>
              <ng-container matColumnDef="phoneNumber">
                <th mat-header-cell *matHeaderCellDef>phoneNumber</th>
                <td mat-cell *matCellDef="let row" class="border-0">{{ row.phoneNumber }}</td>
              </ng-container>
              <ng-container matColumnDef="cognitoId">
                <th mat-header-cell *matHeaderCellDef>cognitoId</th>
                <td mat-cell *matCellDef="let row" class="border-0">{{ row.cognitoId }}</td>
              </ng-container>
              <!-- actions -->
              <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef class="text-center">Actions</th>

                <mat-cell *matCellDef="let row; let i = index" class="border-0 flex justify-center items-center">
                  <button mat-icon-button color="accent" (click)="viewFleetAdminDetail(row.id)">
                    <mat-icon svgIcon="heroicons_outline:eye"></mat-icon>
                  </button>
                  <button mat-icon-button color="primary" (click)="editFleetAdmin(i, row)">
                    <mat-icon svgIcon="heroicons_outline:pencil"></mat-icon>
                  </button>
                  <button mat-icon-button color="warn" (click)="deleteFleetAdmin(row.id)">
                    <mat-icon svgIcon="heroicons_outline:trash"></mat-icon>
                  </button>
                </mat-cell>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
              <tr
                mat-row
                *matRowDef="let row; columns: displayedColumns"
                class="border-gray-200 border-b-[1px]"
                [ngClass]="isRowDisabled(row) ? 'disabled-row' : ''"
              ></tr>
            </table>
            <div *ngIf="!this.dataSource?.data.length" class="text-lg text-center">No TCO Fleet Admins</div>
            <mat-paginator
              #paginator
              [length]="totalData"
              [pageSizeOptions]="pageSizes"
              showFirstLastButtons
            ></mat-paginator>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
