import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { CommonService } from 'app/shared/common.service';

@Component({
  selector: 'app-organisation-detail',
  templateUrl: './organisation-detail.component.html',
  styleUrls: ['./organisation-detail.component.scss'],
})
export class OrganisationDetailComponent {
  orgId;
  organisationDetails;
  constructor(private commonService: CommonService, private route: ActivatedRoute, private router: Router) {}
  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      const id = params.get('orgId');
      this.orgId = id;
    });
    this.commonService.getOrganisationById(this.orgId).subscribe({
      next: data => {
        this.organisationDetails = data;
      },
      error: error => {
        console.log('Failed to fetch organisation by Id.', error);
      },
    });
  }
}
