<mat-list>
  <mat-list-item class="p-0">
    <div class="flex justify-between">
      <div
        mat-dialog-title
        class="text-2xl text-primary items-center flex justify-center font-bold"
        [ngSwitch]="data?.dialogType"
      >
        <span *ngSwitchCase="'createOrg'">Create Organisation</span>
        <span *ngSwitchCase="'attachOrgAdmin'">Attach Organisation Admin</span>
      </div>
      <button mat-icon-button class="close-button" [mat-dialog-close]="true" (click)="onCloseDialog(data?.dialogType)">
        <mat-icon class="close-icon" color="warn">close</mat-icon>
      </button>
    </div>
  </mat-list-item>
  <mat-divider></mat-divider>
</mat-list>
<mat-dialog-content class="mat-typography">
  <form [formGroup]="form" *ngIf="data?.dialogType == 'createOrg'">
    <div class="grid grid-cols-1 gap-4">
      <mat-list>
        <mat-list-item class="p-0">
          <h1 class="text-2xl text-primary">Logo</h1>
        </mat-list-item>
        <mat-divider></mat-divider>
      </mat-list>
      <div class="text-xl font-medium text-primary mb-1">
        <input type="file" (change)="fileChangeEvent($event)" />
      </div>

      <div
        class="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 grid-cols-1 gap-4"
        *ngIf="this.imageChangedEvent"
      >
        <div>
          <image-cropper
            [imageChangedEvent]="imageChangedEvent"
            [maintainAspectRatio]="true"
            [aspectRatio]="1 / 1"
            [containWithinAspectRatio]="true"
            format="png"
            (imageCropped)="imageCropped($event)"
            (imageLoaded)="imageLoaded()"
            (cropperReady)="cropperReady()"
            (loadImageFailed)="loadImageFailed()"
          ></image-cropper>
        </div>
        <div *ngIf="croppedImage">
          <img [src]="croppedImage" />
        </div>
      </div>
    </div>
    <mat-list>
      <mat-list-item class="p-0">
        <h1 class="text-2xl text-primary">Detail</h1>
      </mat-list-item>
      <mat-divider></mat-divider>
    </mat-list>
    <div class="grid grid-cols-1 gap-4">
      <div class="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 grid-cols-1 gap-4">
        <!-- Organisation Name -->
        <div>
          <div class="font-medium text-primary mb-1">Organisation Name <span class="text-red-500">*</span></div>
          <mat-form-field class="w-full">
            <input matInput formControlName="orgName" placeholder="Organisation Name" />
            <mat-error *ngIf="form?.get('orgName').hasError('required')"> organization Name is required </mat-error>
          </mat-form-field>
        </div>
        <!-- VAT details -->
        <div>
          <div class="font-medium text-primary mb-1">VAT <span class="text-red-500">*</span></div>
          <mat-form-field class="w-full">
            <input matInput formControlName="vat" placeholder="VAT" />
            <mat-error *ngIf="form?.get('vat').hasError('required')"> VAT is required </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
    <mat-list>
      <mat-list-item class="p-0">
        <h1 class="text-2xl text-primary">Address info</h1>
      </mat-list-item>
      <mat-divider></mat-divider>
    </mat-list>
    <!-- second part -->
    <div class="grid grid-cols-1 gap-4">
      <div class="grid grid-cols-1 gap-4">
        <!-- Address details -->
        <div class="font-medium text-primary mb-1">Address <span class="text-red-500">*</span></div>
        <mat-form-field class="w-full">
          <input matInput formControlName="address" placeholder="address" />
          <mat-error *ngIf="form?.get('address').hasError('required')"> address is required </mat-error>
        </mat-form-field>
      </div>
      <div class="grid grid-cols-1 gap-4">
        <div class="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 grid-cols-1 gap-4">
          <!-- City  details -->
          <div>
            <div class="font-medium text-primary mb-1">City <span class="text-red-500">*</span></div>
            <mat-form-field class="w-full">
              <input matInput formControlName="city" placeholder="City " />
              <mat-error *ngIf="form?.get('city').hasError('required')"> city is required </mat-error>
            </mat-form-field>
          </div>
          <!-- postalCode details -->
          <div>
            <div class="font-medium text-primary mb-1">Postal Code <span class="text-red-500">*</span></div>
            <mat-form-field class="w-full">
              <input matInput formControlName="postalCode" placeholder="postalCode" />
              <mat-error *ngIf="form?.get('postalCode').hasError('required')"> postalCode is required </mat-error>
            </mat-form-field>
          </div>
          <!-- state details -->
          <div>
            <div class="font-medium text-primary mb-1">State <span class="text-red-500">*</span></div>
            <mat-form-field class="w-full">
              <input matInput formControlName="state" placeholder="State" />
              <mat-error *ngIf="form?.get('state').hasError('required')"> state is required </mat-error>
            </mat-form-field>
          </div>
          <!-- Organisation Country -->
          <div>
            <div class="font-medium text-primary mb-1">Country <span class="text-red-500">*</span></div>
            <mat-form-field class="w-full">
              <mat-select
                placeholder="select Country"
                formControlName="country"
                (selectionChange)="changeCountry($event)"
              >
                <mat-option
                  value="option"
                  *ngFor="let country of this.data?.countryInfo?.countries"
                  [value]="country"
                  >{{ country.name }}</mat-option
                >
              </mat-select>
              <mat-error *ngIf="form?.get('country').hasError('required')"> country is required </mat-error>
            </mat-form-field>
          </div>
        </div>
        <!-- Region Details -->
        <div class="font-medium text-primary mb-1" *ngIf="isBE">Region <span class="text-red-500">*</span></div>
        <mat-form-field class="w-full" *ngIf="isBE">
          <mat-select placeholder="select Option" formControlName="region">
            <mat-option value="option" *ngFor="let region of this.data?.countryInfo?.regions" [value]="region.name">{{
              region.name
            }}</mat-option>
          </mat-select>
          <mat-error *ngIf="form?.get('region').hasError('required')"> region is required </mat-error>
        </mat-form-field>
      </div>
    </div>
    <mat-list>
      <mat-list-item class="p-0">
        <h1 class="text-2xl text-primary">Admin info</h1>
      </mat-list-item>
      <mat-divider></mat-divider>
    </mat-list>
    <!-- third part -->
    <div class="grid grid-cols-1 gap-4">
      <div class="grid grid-cols-1 gap-4">
        <div class="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 grid-cols-1 gap-4">
          <!-- Admin First name -->
          <div>
            <div class="font-medium text-primary mb-1">Admin FirstName <span class="text-red-500">*</span></div>
            <mat-form-field class="w-full">
              <input matInput formControlName="adminName" placeholder="firstName " />
              <mat-error *ngIf="form?.get('adminName').hasError('required')"> Admin firstName is required </mat-error>
            </mat-form-field>
          </div>
          <!-- Admin last Name -->
          <div>
            <div class="font-medium text-primary mb-1">Admin LastName <span class="text-red-500">*</span></div>
            <mat-form-field class="w-full">
              <input matInput formControlName="adminLastName" placeholder="LastName" />
              <mat-error *ngIf="form?.get('adminLastName').hasError('required')">
                Admin lastName is required
              </mat-error>
            </mat-form-field>
          </div>
          <!-- Admin Email -->
          <div>
            <div class="font-medium text-primary mb-1">Admin Email <span class="text-red-500">*</span></div>
            <mat-form-field class="w-full">
              <input matInput formControlName="adminEmail" type="email" placeholder="Email" />
              <mat-error *ngIf="form?.get('adminEmail').hasError('email')"> Invalid email format. </mat-error>
              <mat-error *ngIf="form?.get('adminEmail').hasError('required')"> Admin Email is required </mat-error>
            </mat-form-field>
          </div>
          <!-- Admin Phone -->
          <div>
            <div class="font-medium text-primary mb-1">Admin Phone <span class="text-red-500">*</span></div>
            <mat-form-field class="w-full">
              <input matInput formControlName="adminPhone" placeholder="Admin Phone" />
              <mat-error *ngIf="form?.get('adminPhone').hasError('required')"> Admin Phone is required </mat-error>
            </mat-form-field>
          </div>
          <!-- feature flag for Order Module -->
          <div>
            <div class="font-medium text-primary mb-1">Enable Order Feature <span class="text-red-500"></span></div>
            <mat-slide-toggle formControlName="enableOrderFeature" [color]="'primary'"></mat-slide-toggle>
          </div>
        </div>
      </div>
    </div>
  </form>
  <form [formGroup]="adminForm" *ngIf="data?.dialogType == 'attachOrgAdmin'">
    <mat-list class="flex">
      <mat-list-item class="p-0">
        <h1 class="text-2xl text-primary">Organisation</h1>
      </mat-list-item>
      <mat-list-item class="p-0">
        <h1 class="text-2xl text-primary">{{ data?.org.name }}</h1>
      </mat-list-item>
      <mat-divider></mat-divider>
    </mat-list>
    <div class="grid grid-cols-1 gap-4">
      <div class="font-medium text-primary mb-1">Admins <span class="text-red-500">*</span></div>
      <mat-form-field class="w-full">
        <mat-select placeholder="select Option" formControlName="attachAdmin">
          <mat-option value="option" *ngFor="let admin of this.adminList" [value]="admin">{{ admin.email }}</mat-option>
        </mat-select>
        <mat-error *ngIf="adminForm?.get('attachAdmin').hasError('required')"> please select Admin </mat-error>
      </mat-form-field>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close (click)="onCloseDialog(data?.dialogType)">Cancel</button>
  <button
    mat-raised-button
    class="capitalize"
    color="primary"
    [mat-dialog-close]="true"
    cdkFocusInitial
    [ngSwitch]="data?.dialogType"
    (click)="data?.dialogType == 'attachOrgAdmin' ? attachAdminToOrganisation() : addOrganisation()"
    [disabled]="data?.dialogType == 'attachOrgAdmin' ? adminForm.invalid : form.invalid"
  >
    <span *ngSwitchCase="'createOrg'"> Create Organisation </span>
    <span *ngSwitchCase="'attachOrgAdmin'"> Attach Organisation Admin </span>
  </button>
</mat-dialog-actions>
