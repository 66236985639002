import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from 'app/shared/common.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-editdialog',
  templateUrl: './editdialog.component.html',
  styleUrls: ['./editdialog.component.scss'],
})
export class EditdialogComponent {
  form: FormGroup;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  uploadImage;
  constructor(
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private _snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<EditdialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private sanitizer: DomSanitizer,
  ) {}

  ngOnInit() {
    this.form = this.formBuilder.group({
      orgName: ['', Validators.required],
      vat: ['', Validators.required],
      country: ['', Validators.required],
      address: ['', Validators.required],
      postalCode: ['', Validators.required],
      city: ['', Validators.required],
      state: ['', Validators.required],
      region: [''],
      logoPath: [''],
      enableOrderFeature: [false, Validators.required],
    });
    this.setFormData(this.data.orgDetail);
  }
  /**
   * The function closes a dialog and returns a form.
   */
  onCloseDialog() {
    this.dialogRef.close();
  }
  /**
   * The function sets form data by assigning values to specific form fields.
   * @param data - The `data` parameter is an object that contains information about an organization,
   * including its name, VAT number, country, address, postal code, city, state, and region.
   */
  setFormData(data) {
    let country = {
      id: data.country.id,
      code: data.country.code,
      name: data.country.name,
    };
    this.croppedImage = data.logoPath;
    this.form.setValue({
      orgName: data.name,
      vat: data.vat,
      country: data.country.name,
      address: data.address,
      postalCode: data.postalCode,
      city: data.city,
      state: data.state,
      region: data.regionBe,
      logoPath: data.logoPath,
      enableOrderFeature: data.settings.orderModule,
    });
  }

  /**
   * This function updates an organization's details with the input provided in the form.
   */
  updateOrganisation() {
    let countryDetails = this.data.countryDetails.countries.find(country => country.name === this.form.value.country);
    let region = this.form.value.country.toLowerCase() == 'belgium' ? this.form.value.region : null;
    if (this.uploadImage) {
      let organisationInput = {
        id: this.data.orgDetail.id,
        address: this.form.value.address,
        city: this.form.value.city,
        country: countryDetails.id,
        logoPath: '',
        name: this.form.value.orgName,
        postalCode: this.form.value.postalCode,
        regionBe: region,
        state: this.form.value.state,
        vat: this.form.value.vat,
        settings: {
          orderModule: this.form.value.enableOrderFeature,
        },
      };
      this.onUpload().subscribe({
        next: data => {
          organisationInput.logoPath = data.path;
          this.commonService.updateOrganisation(organisationInput).subscribe({
            next: data => {
              this._snackBar.open('Organisation updated successfully.', 'close', {
                horizontalPosition: 'right',
                verticalPosition: 'top',
                duration: 1500,
              });
              this.dialogRef.close(data);
            },
            error: error => {
              console.log('Failed to update organisation.', error);
              this._snackBar.open(' Failed to update Organisation.', 'close', {
                horizontalPosition: 'right',
                verticalPosition: 'top',
                duration: 1500,
              });
            },
          });
          this.dialogRef.close(data);
        },
        error: error => {
          console.log('Error while Upload Logo.', error);
        },
      });
    } else {
      let organisationInput = {
        id: this.data.orgDetail.id,
        address: this.form.value.address,
        city: this.form.value.city,
        country: countryDetails.id,
        name: this.form.value.orgName,
        postalCode: this.form.value.postalCode,
        regionBe: region,
        state: this.form.value.state,
        vat: this.form.value.vat,
        settings: {
          orderModule: this.form.value.enableOrderFeature,
        },
      };
      this.commonService.updateOrganisation(organisationInput).subscribe({
        next: data => {
          this._snackBar.open('Organisation updated successfully.', 'close', {
            horizontalPosition: 'right',
            verticalPosition: 'top',
            duration: 1500,
          });
          this.dialogRef.close(data);
        },
        error: error => {
          console.log('Failed to update organisation.', error);
          this._snackBar.open(' Failed to update Organisation.', 'close', {
            horizontalPosition: 'right',
            verticalPosition: 'top',
            duration: 1500,
          });
        },
      });
    }
  }
  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = this.sanitizer.bypassSecurityTrustUrl(event.objectUrl);
    this.uploadImage = event.blob;
    // event.blob can be used to upload the cropped image
  }
  onUpload() {
    return this.commonService.upload(this.uploadImage);
  }
}
