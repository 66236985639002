import { Component, SimpleChanges, ViewChild } from '@angular/core';
import { Organisation, OrganisationInfo } from 'app/Models/organisation';
import { CommonService } from 'app/shared/common.service';
import { MatDialog } from '@angular/material/dialog';
import { AdddialogComponent } from '../adddialog/adddialog.component';
import { EditdialogComponent } from '../editdialog/editdialog.component';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { catchError, map, startWith, switchMap, merge } from 'rxjs';
import { MatSort, SortDirection } from '@angular/material/sort';
import { FormControl } from '@angular/forms';

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}
@Component({
  selector: 'app-organisations',
  templateUrl: './organisations.component.html',
  styleUrls: ['./organisations.component.scss'],
})
export class OrganisationsComponent {
  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = ['logo', 'name', 'created', 'actions'];
  countryDetails: any = {};
  totalData;
  lastPage;
  searchValid;
  organisations: Organisation[];
  pageSizes = [5, 10];
  dataSource: MatTableDataSource<Organisation>;
  searchKeywordFilter = new FormControl();
  constructor(private commonService: CommonService, public dialog: MatDialog, private router: Router) {
    this.dataSource = new MatTableDataSource<Organisation>([]);
  }
  ngOnInit(): void {
    this.commonService.fetchCountries().subscribe(contryDetails => {
      this.countryDetails.countries = contryDetails?.countries;
      this.countryDetails.regions = contryDetails?.regions;
    });
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    this.searchKeywordFilter.valueChanges.subscribe({
      next: () => {
        this.searchValid = this.searchKeywordFilter.value.length >= 3 ? true : false;
        this.paginator.pageIndex = 0;
      },
    });
    merge(this.searchKeywordFilter.valueChanges, this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          var filterValue =
            this.searchKeywordFilter.value == null || !this.searchValid ? '' : this.searchKeywordFilter.value;
          return this.getOrganisationData(
            this.paginator.pageIndex + 1,
            this.paginator.pageSize,
            this.sort.active,
            this.sort.direction,
            filterValue,
          ).pipe(catchError(async error => console.log('Error fetch Organisation Admin', error)));
        }),
        map((details: OrganisationInfo) => {
          if (details == null) return [];
          this.totalData = details.paginatorInfo.total;
          this.lastPage = details.paginatorInfo.lastPage;
          return details.data;
        }),
      )
      .subscribe({
        next: orgDetails => {
          this.organisations = orgDetails;
          this.dataSource = new MatTableDataSource(orgDetails);
        },
        error: error => {
          console.log('Error while Fetch Organisations.', error);
        },
      });
  }
  getOrganisationData(pageNumber: Number, pageSize: Number, sort: string, orderBy: SortDirection, filter: string) {
    return this.commonService.fetchOrganisations(pageNumber, pageSize, sort, orderBy, filter);
  }
  /**
   * The function "viewOrganisationDetail" takes in an organization ID as a parameter.
   * @param orgId - The orgId parameter is likely a unique identifier for an organization, which could
   * be used to retrieve or display detailed information about that organization.
   */
  viewOrganisationDetail(orgId) {
    this.router.navigate(['/organisation', orgId]);
  }
  /**
   * This function opens a dialog box and updates the data source after the dialog box is closed.
   */
  openDialog() {
    const dialogRef = this.dialog.open(AdddialogComponent, {
      disableClose: true,
      maxWidth: '100vw',
      maxHeight: '100vh',
      width: '75vw',
      panelClass: 'full-screen-modal',
      data: { countryInfo: this.countryDetails, dialogType: 'createOrg' },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.paginator.pageIndex = 0;
        this.getOrganisationData(
          this.paginator.pageIndex + 1,
          this.paginator.pageSize,
          this.sort.active,
          this.sort.direction,
          '',
        ).subscribe({
          next: (organisationData: OrganisationInfo) => {
            this.paginator.length = this.paginator.length + 1;
            this.dataSource = new MatTableDataSource(organisationData.data);
          },
          error: error => {
            console.log('Error while refetch organisation Data.');
          },
        });
      }
    });
  }
  /**
   * This function opens a dialog box to edit an organization's details and updates the list of
   * organizations after the dialog box is closed.
   * @param i - The index of the organisation in the array of organisations.
   * @param orgId - orgId is a parameter that represents the ID of the organization that needs to be
   * edited. It is used to find the specific organization from the list of organizations and pass its
   * details to the dialog component for editing.
   */
  editOrganisation(i, orgId) {
    let editOrganisationDetail = this.organisations.find(organisation => organisation.id == orgId);
    const dialogRef = this.dialog.open(EditdialogComponent, {
      disableClose: true,
      maxWidth: '100vw',
      maxHeight: '100vh',
      width: '75vw',
      panelClass: 'full-screen-modal',
      data: { orgDetail: editOrganisationDetail, countryDetails: this.countryDetails },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getOrganisationData(
          this.paginator.pageIndex + 1,
          this.paginator.pageSize,
          this.sort.active,
          this.sort.direction,
          '',
        ).subscribe({
          next: (organisationData: OrganisationInfo) => {
            this.organisations = organisationData.data;
            this.dataSource = new MatTableDataSource(organisationData.data);
          },
          error: error => {
            console.log('Error while refetch organisation Data.');
          },
        });
      }
    });
  }
  /**
   * The function deletes an organization and updates the list of organizations displayed in the UI.
   * @param orgId - orgId is a parameter that represents the ID of the organization that needs to be
   * deleted. It is passed as data to the ConfirmDialogComponent, which is a dialog box that asks the
   * user to confirm the deletion. If the user confirms the deletion, the function fetches the
   * updated list of organizations
   */
  deleteOrganisation(orgId) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: true,
      data: { actionType: 'deleteAdmin', id: orgId },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.paginator.pageIndex = 0;
        this.getOrganisationData(
          this.paginator.pageIndex + 1,
          this.paginator.pageSize,
          this.sort.active,
          this.sort.direction,
          '',
        ).subscribe({
          next: (organisationData: OrganisationInfo) => {
            this.paginator.length = this.paginator.length - 1;
            this.dataSource = new MatTableDataSource(organisationData.data);
          },
          error: error => {
            console.log('Error while refetch organisation Data.');
          },
        });
      }
    });
  }
}
