import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, Observable, of, switchMap, throwError } from 'rxjs';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { UserService } from 'app/core/user/user.service';
import { AuthenticationDetails, CognitoRefreshToken, CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js';
import { EnvironmentService } from '../../../environments/environment.service';
import { Amplify, Auth } from 'aws-amplify';

@Injectable()
export class AuthService {
  private _authenticated: boolean = false;
  private _cognitoPool: CognitoUserPool = null;
  public userEmail = '';
  public forceChangePassword: boolean = false;
  public cognitoUser;

  /**
   * Constructor
   */
  constructor(
    private _httpClient: HttpClient,
    private _userService: UserService,
    private readonly environment: EnvironmentService,
  ) {
    this._cognitoPool = new CognitoUserPool({
      UserPoolId: environment.awsCognito.userPoolId,
      ClientId: environment.awsCognito.userPoolWebClientId,
    });
    const user = this._userService.user$;
    if (user && this.accessToken) {
      this._authenticated = true;
    }
    Amplify.configure({
      Auth: environment.awsCognito,
      // oauth: {
      //   domain: "https://mobility-buddy-local.auth.eu-west-1.amazoncognito.com/",
      //   scope: ["email", "openid", "profile"],
      //   redirectSignIn: "http://localhost:4200/sign-in",
      //   redirectSignOut: "http://localhost:4200/sign-out",
      //   responseType: "code"
      // }
    });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for access token
   */
  set accessToken(token: string) {
    localStorage.setItem('accessToken', token);
  }

  get accessToken(): string {
    return localStorage.getItem('accessToken') ?? '';
  }
  /**
   * Setter & getter for refresh token
   */
  set refreshToken(token: string) {
    localStorage.setItem('refreshToken', token);
  }

  get refreshToken(): string {
    return localStorage.getItem('refreshToken') ?? '';
  }

  getCognito(username: string): CognitoUser {
    const userData = { Username: username, Pool: this._cognitoPool };
    return new CognitoUser(userData);
  }
  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Forgot password
   *
   * @param email
   */
  forgotPassword(email: string): Observable<any> {
    return this._httpClient.post('api/auth/forgot-password', email);
  }

  /**
   * Reset password
   *
   * @param password
   */
  resetPassword(password: string): Observable<any> {
    return this._httpClient.post('api/auth/reset-password', password);
  }

  /**
   * Sign in
   *
   * @param credentials
   */
  signIn(credentials: { email: string; password: string }): Observable<any> {
    // Throw error, if the user is already logged in
    if (this._authenticated) {
      return throwError('User is already logged in.');
    }

    return this._httpClient.post('api/auth/sign-in', credentials).pipe(
      switchMap((response: any) => {
        // Store the access token in the local storage
        this.accessToken = response.accessToken;

        // Set the authenticated flag to true
        this._authenticated = true;

        // Store the user on the user service
        this._userService.user = response.user;

        // Return a new observable with the response
        return of(response);
      }),
    );
  }

  /**
   * Sign in using the access token
   */
  signInUsingToken(): Observable<any> {
    // Sign in using the token
    return this._httpClient
      .post('api/auth/sign-in-with-token', {
        accessToken: this.accessToken,
      })
      .pipe(
        catchError(() =>
          // Return false
          of(false),
        ),
        switchMap((response: any) => {
          // Replace the access token with the new one if it's available on
          // the response object.
          //
          // This is an added optional step for better security. Once you sign
          // in using the token, you should generate a new one on the server
          // side and attach it to the response object. Then the following
          // piece of code can replace the token with the refreshed one.
          if (response.accessToken) {
            this.accessToken = response.accessToken;
          }

          // Set the authenticated flag to true
          this._authenticated = true;

          // Store the user on the user service
          this._userService.user = response.user;

          // Return true
          return of(true);
        }),
      );
  }

  /**
   * Sign out
   */
  signOut(): Observable<any> {
    // Remove the access token from the local storage
    localStorage.removeItem('accessToken');

    // Set the authenticated flag to false
    this._authenticated = false;

    // Return the observable
    return of(true);
  }

  /**
   * Sign up
   *
   * @param user
   */
  signUp(user: { name: string; email: string; password: string; company: string }): Observable<any> {
    return this._httpClient.post('api/auth/sign-up', user);
  }

  /**
   * Unlock session
   *
   * @param credentials
   */
  unlockSession(credentials: { email: string; password: string }): Observable<any> {
    return this._httpClient.post('api/auth/unlock-session', credentials);
  }

  /**
   * Check the authentication status
   */
  check(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (this._authenticated) {
        // Check if the user is logged in
        return resolve(true);
      }

      if (!this.accessToken) {
        return resolve(false);
      }

      // Check the access token expire date
      if (AuthUtils.isTokenExpired(this.accessToken)) {
        // TODO: use refresh token here.
        return resolve(false);
      }
    });
  }

  /*Authentication with Amplify*/

  /* Amplify SignIn */
  public SignIn(credentials: { Username: string; Password: string }): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      Auth.signIn(credentials.Username, credentials.Password)
        .then(result => {
          if (result?.challengeName === 'NEW_PASSWORD_REQUIRED') {
            this.forceChangePassword = true;
            this.cognitoUser = result;
            resolve(result);
          } else {
            this.accessToken = result?.signInUserSession?.accessToken?.jwtToken;
            this.refreshToken = result?.signInUserSession?.refreshToken?.token;
            this._userService.user = {
              id: result?.attributes?.sub,
              name: `${result?.attributes?.given_name} ${result?.attributes?.family_name}`,
              email: result?.attributes?.email,
            };
            this._authenticated = true;
            resolve(result);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  /* Amplify SignOut */
  public SignOut(): Promise<any> {
    return Auth.signOut().then(() => {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('user');
      // Set the authenticated flag to false
      this._authenticated = false;
    });
  }
  public forgotpassword(credentials: { Username: string }): Promise<any> {
    return Auth.forgotPassword(credentials.Username);
  }

  public forgotPasswordSubmit(credentials: { Username: string; code: string; Password: string }): Promise<any> {
    return Auth.forgotPasswordSubmit(credentials.Username, credentials.code, credentials.Password);
  }

  public forceChagePassword(newPassword): Promise<any> {
    if (this.forceChangePassword && this.cognitoUser) {
      return Auth.completeNewPassword(
        this.cognitoUser, // the Cognito User Object
        newPassword, // the new password
      )
        .then(user => {
          // at this time the user is logged in if no MFA required
        })
        .catch(e => {
          console.log(e);
        });
    }
  }
}
