<div class="flex flex-col flex-auto min-w-0">
  <!-- Main -->
  <div class="flex-auto p-6 sm:p-10">
    <!-- CONTENT GOES HERE -->
    <div class="border-2 border-dashed border-primary-300 rounded-2xl">
      <div class="grid grid-cols-1 gap-4 rounded-lg text-primary-500 bg-white m-4 p-4">
        <h1 class="text-3xl break-words">TCO Fleet Admin Details::</h1>
        <div class="flex">
          <div class="flex-none lg:w-36 md:w-36 sm:w-18 w-18">
            <span class="text-xl text-primary-500">ID</span>
          </div>
          <div class="flex-auto w-64 break-all">
            <span class="text-lg text-secondary">{{ fleetAdminDetails?.id }}</span>
          </div>
        </div>
        <mat-divider></mat-divider>
        <div class="flex">
          <div class="flex-none lg:w-36 md:w-36 sm:w-18 w-18">
            <span class="text-xl text-primary-500">First Name</span>
          </div>
          <div class="flex-auto w-64 break-all">
            <span class="text-lg text-secondary">{{ fleetAdminDetails?.firstName }}</span>
          </div>
        </div>
        <mat-divider></mat-divider>
        <div class="flex">
          <div class="flex-none lg:w-36 md:w-36 sm:w-18 w-18">
            <span class="text-xl text-primary-500">Last Name:</span>
          </div>
          <div class="flex-auto w-64 break-all">
            <span class="text-lg text-secondary">{{ fleetAdminDetails?.lastName }}</span>
          </div>
        </div>
        <mat-divider></mat-divider>
        <div class="flex">
          <div class="flex-none lg:w-36 md:w-36 sm:w-18 w-18">
            <span class="text-xl text-primary-500">Email</span>
          </div>
          <div class="flex-auto w-64 break-all">
            <span class="text-lg text-secondary">{{ fleetAdminDetails?.email }}</span>
          </div>
        </div>
        <mat-divider></mat-divider>
        <div class="flex">
          <div class="flex-none lg:w-36 md:w-36 sm:w-18 w-18">
            <span class="text-xl text-primary-500">Telephone</span>
          </div>
          <div class="flex-auto w-64 break-all">
            <span class="text-lg text-secondary">{{ fleetAdminDetails?.phoneNumber }}</span>
          </div>
        </div>
        <mat-divider></mat-divider>
        <div class="flex">
          <div class="flex-none lg:w-36 md:w-36 sm:w-18 w-18">
            <span class="text-xl text-primary-500">Cognito ID:</span>
          </div>
          <div class="flex-auto w-64 break-all">
            <span class="text-lg text-secondary">{{ fleetAdminDetails?.cognitoId }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
