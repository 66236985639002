<div class="flex flex-col flex-auto min-w-0">
  <!-- Main -->
  <div class="flex-auto p-6 sm:p-10">
    <!-- CONTENT GOES HERE -->
    <div class="h-180 min-h-180 max-h-400 border-2 border-dashed border-primary-300 rounded-2xl">
      <div class="grid grid-cols-1 gap-4 rounded-lg text-primary-500 bg-white m-4 p-4">
        <h1 class="text-3xl break-words flex justify-between">
          <span>Profile Details:</span>
          <button mat-icon-button color="primary" (click)="editProfile(profileDetail)">
            <mat-icon svgIcon="heroicons_outline:pencil"></mat-icon>
          </button>
        </h1>
        <div class="flex">
          <div class="flex-none lg:w-36 md:w-36 sm:w-18 w-18">
            <span class="text-xl text-primary-500">ID</span>
          </div>
          <div class="flex-auto w-64 break-all">
            <span class="text-lg text-secondary">{{ profileDetail?.id }}</span>
          </div>
        </div>
        <mat-divider></mat-divider>
        <div class="flex">
          <div class="flex-none lg:w-36 md:w-36 sm:w-18 w-18">
            <span class="text-xl text-primary-500">First name</span>
          </div>
          <div class="flex-auto w-64 break-all">
            <span class="text-lg text-secondary">{{ profileDetail?.firstName }}</span>
          </div>
        </div>
        <mat-divider></mat-divider>
        <div class="flex">
          <div class="flex-none lg:w-36 md:w-36 sm:w-18 w-18">
            <span class="text-xl text-primary-500">Last name</span>
          </div>
          <div class="flex-auto w-64 break-all">
            <span class="text-lg text-secondary">{{ profileDetail?.lastName }}</span>
          </div>
        </div>
        <mat-divider></mat-divider>
        <div class="flex">
          <div class="flex-none lg:w-36 md:w-36 sm:w-18 w-18">
            <span class="text-xl text-primary-500">Email</span>
          </div>
          <div class="flex-auto w-64 break-all">
            <span class="text-lg text-secondary">{{ profileDetail?.email }}</span>
          </div>
        </div>
        <mat-divider></mat-divider>
        <div class="flex">
          <div class="flex-none lg:w-36 md:w-36 sm:w-18 w-18">
            <span class="text-xl text-primary-500">Phone</span>
          </div>
          <div class="flex-auto w-64 break-all">
            <span class="text-lg text-secondary">{{ profileDetail?.phoneNumber }}</span>
          </div>
        </div>
        <mat-divider></mat-divider>
        <div class="flex">
          <div class="flex-none lg:w-36 md:w-36 sm:w-18 w-18">
            <span class="text-xl text-primary-500">Cognito ID</span>
          </div>
          <div class="flex-auto w-64 break-all">
            <span class="text-lg text-secondary">{{ profileDetail?.cognitoId }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
