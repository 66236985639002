import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { ME_QUERY } from 'app/graphQL/me';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private apollo: Apollo) {}
  fetchUserData(): Observable<any> {
    return this.apollo.watchQuery({
      query: ME_QUERY,
    }).valueChanges;
  }
}
