import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Organisation } from 'app/Models/organisation';
import { CommonService } from 'app/shared/common.service';

@Component({
  selector: 'app-attach-organisation-dialog',
  templateUrl: './attach-organisation-dialog.component.html',
  styleUrls: ['./attach-organisation-dialog.component.scss'],
})
export class AttachOrganisationDialogComponent {
  orgForm: FormGroup;
  orgList: Organisation[] = [];
  constructor(
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private _snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<AttachOrganisationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}
  ngOnInit(): void {
    this.orgForm = this.formBuilder.group({
      attachOrgnisation: ['', Validators.required],
    });
    this.commonService.fetchOrganisations(1, 1000, 'name', 'ASC', '').subscribe({
      next: organisations => {
        this.orgList = organisations.data;
      },
      error: error => {
        console.log('Error while Fetch Organisations.', error);
      },
    });
  }
  attachOrganisationToUser() {
    let updteObject = this.data.currentUser.organisation
      ? { connect: this.orgForm.value.attachOrgnisation.id, disconnect: this.data.currentUser.organisation.id }
      : { connect: this.orgForm.value.attachOrgnisation.id };

    let updateUserInput = {
      id: this.data.currentUser.id,
      organisation: updteObject,
    };
    this.commonService.updateUser(updateUserInput).subscribe({
      next: data => {
        this._snackBar.open('attach Organisation to user successfully.', 'close', {
          horizontalPosition: 'right',
          verticalPosition: 'top',
          duration: 1500,
        });
      },
      error: error => {
        console.log('Failed to attach organisation to admin.', error);
        this._snackBar.open('Failed to attach Organisation to user.', 'close', {
          horizontalPosition: 'right',
          verticalPosition: 'top',
          duration: 1500,
        });
      },
    });
  }
  onCloseDialog() {
    this.dialogRef.close();
  }
}
