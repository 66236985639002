import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommonService } from 'app/shared/common.service';
import { UserService } from 'app/shared/user.service';

@Component({
  selector: 'app-user-edit-dialog',
  templateUrl: './user-edit-dialog.component.html',
  styleUrls: ['./user-edit-dialog.component.scss'],
})
export class UserEditDialogComponent {
  userForm: FormGroup;
  loginUser: boolean = false;
  constructor(
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private _snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<UserEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _userService: UserService,
  ) {
    this._userService.fetchUserData().subscribe(fetchResult => {
      this.commonService.currentLoginUserId = fetchResult.data.me.id;
    });
  }
  ngOnInit(): void {
    this.userForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: [{ value: '', disabled: true }],
      phone: ['', Validators.required],
      cognitoId: [{ value: '', disabled: true }],
    });
    this.setFormData();
  }
  setFormData() {
    this.userForm.setValue({
      firstName: this.data.firstName,
      lastName: this.data.lastName,
      email: this.data.email,
      phone: this.data.phoneNumber,
      cognitoId: this.data?.cognitoId ?? '',
    });
    if (this.commonService.currentLoginUserId == this.data.id) {
      this.loginUser = true;
    }
  }
  updateUser() {
    let updateUserInput = {
      id: this.data.id,
      firstName: this.userForm.value.firstName,
      lastName: this.userForm.value.lastName,
      phoneNumber: this.userForm.value.phone,
    };
    this.commonService.updateUser(updateUserInput).subscribe({
      next: data => {
        this._snackBar.open('Organisation admin updated successfully.', 'close', {
          horizontalPosition: 'right',
          verticalPosition: 'top',
          duration: 1500,
        });
        this.dialogRef.close(data);
      },
      error: error => {
        console.log('Error while update user:', error);
        this._snackBar.open('Failed to update Organisation admin.', 'close', {
          horizontalPosition: 'right',
          verticalPosition: 'top',
          duration: 1500,
        });
      },
    });
  }
  onCloseDialog() {
    this.dialogRef.close();
  }
}
