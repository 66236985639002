import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommonService } from 'app/shared/common.service';
import { ROLES } from 'app/shared/roles.enum';

@Component({
  selector: 'app-tco-fleet-admin-dialog',
  templateUrl: './tco-fleet-admin-dialog.component.html',
  styleUrls: ['./tco-fleet-admin-dialog.component.scss'],
})
export class TcoFleetAdminDialogComponent {
  userForm: FormGroup;
  orgRoleId;
  constructor(
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private _snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<TcoFleetAdminDialogComponent>,
  ) {}

  ngOnInit() {
    this.commonService.fetchRole().subscribe(data => {
      let role = data.find(role => role.slug == ROLES.TCO_FLEET_ADMIN);
      this.orgRoleId = role?.id;
    });
    this.userForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.email, Validators.required]],
      phone: ['', Validators.required],
    });
  }

  addFleetAdmin() {
    let userInput = {
      firstName: this.userForm.value.firstName,
      lastName: this.userForm.value.lastName,
      email: this.userForm.value.email,
      phoneNumber: this.userForm.value.phone,
      roles: { connect: [this.orgRoleId] },
    };
    this.commonService.createOrganisationAdmin(userInput).subscribe({
      next: data => {
        this._snackBar.open('TCO Fleet admin created successfully.', 'close', {
          horizontalPosition: 'right',
          verticalPosition: 'top',
          duration: 1500,
        });
        this.dialogRef.close(data);
      },
      error: error => {
        console.log('Error while create TCO Fleet Admin.', error);
        this._snackBar.open('Failed to create TCO Fleet admin.', 'close', {
          horizontalPosition: 'right',
          verticalPosition: 'top',
          duration: 1500,
        });
      },
    });
  }
  onCloseDialog() {
    this.dialogRef.close();
  }
}
