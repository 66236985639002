import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { User } from 'app/Models/user';
import { CommonService } from 'app/shared/common.service';
import { UserService } from 'app/shared/user.service';
import { UserEditDialogComponent } from '../user-edit-dialog/user-edit-dialog.component';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent {
  profileDetail: User;
  constructor(private _userService: UserService, private commonService: CommonService, public dialog: MatDialog) {
    this._userService.fetchUserData().subscribe(fetchResult => {
      if (fetchResult.data.me.roles) {
        fetchResult.data.me.roles.map(role => {
          if (role.slug == 'tco_fleet_admin') {
            this.commonService.fetchUserById(fetchResult.data.me.id).subscribe({
              next: profileDetail => {
                this.profileDetail = profileDetail;
              },
              error: error => {
                console.log('Error while fetch Profile Data.');
              },
            });
          }
        });
      }
    });
  }

  editProfile(Profile) {
    const dialogRef = this.dialog.open(UserEditDialogComponent, {
      disableClose: true,
      panelClass: 'full-screen-modal',
      width: '75vw',
      data: Profile,
    });
    dialogRef.afterClosed().subscribe(result => {
      this.commonService.fetchUserById(Profile?.id).subscribe({
        next: profileDetail => {
          this.profileDetail = profileDetail;
        },
        error: error => {
          console.log('Error while fetch Profile Data.');
        },
      });
    });
  }
}
