import { gql } from 'apollo-angular';
export const ME_QUERY = gql`
  query Me {
    me {
      companies {
        id
        logoUrl
        name
      }
      email
      firstName
      id
      lastName
      locale
      organisation {
        id
      }
      phoneNumber
      roles {
        id
        slug
      }
    }
  }
`;
