import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'app/shared/common.service';

@Component({
  selector: 'app-service-provider-detail',
  templateUrl: './service-provider-detail.component.html',
  styleUrls: ['./service-provider-detail.component.scss'],
})
export class ServiceProviderDetailComponent {
  providerId;
  serviceProviderDetails;
  constructor(private commonService: CommonService, private route: ActivatedRoute) {}

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      const id = params.get('providerId');
      this.providerId = id;
    });
    this.commonService.fetchSerivceProviderById(this.providerId).subscribe({
      next: data => {
        this.serviceProviderDetails = data;
      },
      error: error => {
        console.log('Failed to fetch organisation by Id.', error);
      },
    });
  }
}
