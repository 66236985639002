<mat-dialog-content class="mat-typography" [ngSwitch]="this.data.actionType">
  <span *ngSwitchCase="'deleteuser'">Are you sure you want to delete this admin user ?</span>
  <span *ngSwitchCase="'detachOrganisationAdmin'">Are you sure you want to detach admin from Organisation ?</span>
  <span *ngSwitchCase="'deleteAdmin'">Are you sure you want to delete this Organisation ?</span>
  <span *ngSwitchCase="'deleteServiceProvider'">Are you sure you want to delete this Service Provider ?</span>
  <span *ngSwitchCase="'deleteFleetAdmin'">Are you sure you want to delete this TCO Fleet Admin?</span>
</mat-dialog-content>
<mat-dialog-actions align="end" [ngSwitch]="this.data.actionType">
  <div *ngSwitchCase="'deleteuser'">
    <button mat-button mat-dialog-close>Cancel</button>
    <button
      mat-raised-button
      class="capitalize"
      color="primary"
      [mat-dialog-close]="true"
      cdkFocusInitial
      (click)="deleteUser(this.data?.id)"
    >
      Delete
    </button>
  </div>
  <div *ngSwitchCase="'deleteAdmin'">
    <button mat-button mat-dialog-close>Cancel</button>
    <button
      mat-raised-button
      class="capitalize"
      color="primary"
      [mat-dialog-close]="true"
      cdkFocusInitial
      (click)="deleteOrganisation(this.data?.id)"
    >
      Delete
    </button>
  </div>
  <div *ngSwitchCase="'detachOrganisationAdmin'">
    <button mat-button mat-dialog-close>Cancel</button>
    <button
      mat-raised-button
      class="capitalize"
      color="primary"
      [mat-dialog-close]="true"
      cdkFocusInitial
      (click)="detachAdminUserFromOrganisation(this.data?.id)"
    >
      Delete
    </button>
  </div>
  <div *ngSwitchCase="'deleteServiceProvider'">
    <button mat-button mat-dialog-close>Cancel</button>
    <button
      mat-raised-button
      class="capitalize"
      color="primary"
      [mat-dialog-close]="true"
      cdkFocusInitial
      (click)="deleteServiceProvider(this.data?.id)"
    >
      Delete
    </button>
  </div>
  <div *ngSwitchCase="'deleteFleetAdmin'">
    <button mat-button mat-dialog-close>Cancel</button>
    <button
      mat-raised-button
      class="capitalize"
      color="primary"
      [mat-dialog-close]="true"
      cdkFocusInitial
      (click)="deleteTCOFleetAdmin(this.data?.id)"
    >
      Delete
    </button>
  </div>
</mat-dialog-actions>
