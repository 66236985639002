import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'app/shared/user.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent {
  /**
   * Constructor
   */
  constructor(private _userService: UserService, private _router: Router) {
    this._userService.fetchUserData().subscribe(fetchResult => {
      if (fetchResult.data.me.roles) {
        console.log('Fetch User:', fetchResult.data.me);
        fetchResult.data.me.roles.map(role => {
          if (role.slug !== 'tco_fleet_admin') {
            this._router.navigate(['/sign-out']);
          }
        });
      }
      // this.employeeService.getEmployeeDetailById(fetchResult.data.me?.id).subscribe((employeeResult: any) => {
      //   this.employeeService.employeeDetail = employeeResult?.data?.userById;
      // });
    });
  }

  ngOnInit(): void {}
}
