import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Apollo, QueryRef } from 'apollo-angular';
import { Organisation, OrganisationInfo } from 'app/Models/organisation';
import {
  CREATE_ORGANISATION,
  DELETE_ORGANISATION,
  FETCH_ORGANISATION_ADMIN_BY_ID,
  FETCH_ORGANISATION_BY_ID,
  ORGANISATIONS_QUERY,
  UPDATE_ORGANISATION,
} from 'app/graphQL/organisations';
import { map } from 'rxjs';
import { COUNTRIES_QUERY } from 'app/graphQL/companyRelations';
import {
  CREATE_ORGANISATION_ADMIN,
  DELETE_USER_BY_ID,
  FETCH_USER_BY_ID,
  UPDATE_ORGANISATION_ADMIN,
} from 'app/graphQL/users';
import { ROLES_QUERY } from 'app/graphQL/roles';
import { ROLES } from './roles.enum';
import { OrganisationAdminInfo, User } from 'app/Models/user';
import {
  CREATE_SERVICE_PROVIDER,
  DELETE_SERVICE_PROVIDER,
  FETCH_SERVICE_PROVIDER_BY_ID,
  SERVICE_PROVIDERS,
  UPDATE_SERVICE_PROVIDER,
} from 'app/graphQL/serviceProvider';
import { ServiceProvider, ServiceProviderInfo } from 'app/Models/serviceProvider';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { UploadCommand } from './types';

interface createOrganisation {
  createOrganisation: Organisation;
}
interface createServiceProvider {
  createServiceProvider: ServiceProvider;
}
interface updateServiceProvider {
  updateServiceProvider: ServiceProvider;
}
interface users {
  users: OrganisationAdminInfo;
}
@Injectable({
  providedIn: 'root',
})
export class CommonService {
  queryRef: QueryRef<any>;
  fetchOrganisationAdminsQueryRef: QueryRef<any>;
  fetchUserByIdRef: QueryRef<any>;
  fetchAllAdminsRef: QueryRef<any>;
  fetchOrganisationByIdRef: QueryRef<any>;
  currentLoginUserId;

  constructor(private apollo: Apollo, private http: HttpClient) {}

  fetchOrganisations(pageNumber, pageSize, column, order, filter): Observable<OrganisationInfo> {
    column = column == 'created' ? 'created_at' : column;
    this.queryRef = this.apollo.watchQuery<any>({
      query: ORGANISATIONS_QUERY,
      variables: {
        first: pageSize,
        page: pageNumber,
        orderBy: [
          {
            column: column.toUpperCase(),
            order: order.toUpperCase(),
          },
        ],
        filter: filter,
        trashed: 'WITHOUT',
      },
      fetchPolicy: 'no-cache',
    });
    return this.queryRef.valueChanges.pipe(map(({ data }) => data.organisations));
  }
  refetchQuery() {
    this.queryRef.refetch();
  }
  fetchCountries(): Observable<any> {
    return this.apollo
      .watchQuery<any>({
        query: COUNTRIES_QUERY,
      })
      .valueChanges.pipe(map(({ data }) => data));
  }
  fetchServiceProvider(pageNumber, pageSize, column, order, filter): Observable<ServiceProviderInfo> {
    return this.apollo
      .watchQuery<any>({
        query: SERVICE_PROVIDERS,
        variables: {
          first: pageSize,
          page: pageNumber,
          orderBy: [
            {
              column: column.toUpperCase(),
              order: order.toUpperCase(),
            },
          ],
          filter: filter,
        },
        fetchPolicy: 'no-cache',
      })
      .valueChanges.pipe(map(({ data }) => data.serviceProviders));
  }
  fetchSerivceProviderById(providerId) {
    return this.apollo
      .watchQuery<any>({
        query: FETCH_SERVICE_PROVIDER_BY_ID,
        variables: { id: providerId },
        fetchPolicy: 'no-cache',
      })
      .valueChanges.pipe(map(({ data }) => data.serviceProviderById));
  }
  addServiceProvider(createServiceProviderInput): Observable<ServiceProvider> {
    return this.apollo
      .mutate<createServiceProvider>({
        mutation: CREATE_SERVICE_PROVIDER,
        variables: { input: createServiceProviderInput },
      })
      .pipe(map(({ data }) => data.createServiceProvider));
  }
  updateServiceProvider(updateServiceProviderInput): Observable<ServiceProvider> {
    return this.apollo
      .mutate<updateServiceProvider>({
        mutation: UPDATE_SERVICE_PROVIDER,
        variables: { input: updateServiceProviderInput },
      })
      .pipe(map(({ data }) => data.updateServiceProvider));
  }
  deleteServiceProvider(providerId) {
    return this.apollo
      .mutate<any>({
        mutation: DELETE_SERVICE_PROVIDER,
        variables: { id: providerId },
      })
      .pipe(map(({ data }) => data.deleteServiceProvider));
  }
  addOrganisation(createCompanyInput): Observable<Organisation> {
    return this.apollo
      .mutate<createOrganisation>({
        mutation: CREATE_ORGANISATION,
        variables: { input: createCompanyInput },
      })
      .pipe(map(({ data }) => data.createOrganisation));
  }
  updateOrganisation(updateOrganisationInput) {
    return this.apollo.mutate<Organisation>({
      mutation: UPDATE_ORGANISATION,
      variables: { input: updateOrganisationInput },
    });
  }
  deleteOrganisation(organisationId) {
    return this.apollo.mutate<Organisation>({
      mutation: DELETE_ORGANISATION,
      variables: { id: organisationId },
    });
  }
  createOrganisationAdmin(userDetails) {
    return this.apollo.mutate({
      mutation: CREATE_ORGANISATION_ADMIN,
      variables: { input: userDetails },
    });
  }
  fetchRole() {
    return this.apollo
      .watchQuery<any>({
        query: ROLES_QUERY,
      })
      .valueChanges.pipe(map(({ data }) => data.roles));
  }
  getOrganisationById(orgId) {
    this.fetchOrganisationByIdRef = this.apollo.watchQuery<any>({
      query: FETCH_ORGANISATION_BY_ID,
      variables: { id: orgId },
    });
    return this.fetchOrganisationByIdRef.valueChanges.pipe(map(({ data }) => data.organisationById));
  }
  refetchOrganisationById(orgId) {
    this.fetchOrganisationByIdRef.refetch({ id: orgId });
  }
  fetchOrganisationAdmins(orgId, pageNumber, pageSize, column, order, filter): Observable<OrganisationAdminInfo> {
    this.fetchOrganisationAdminsQueryRef = this.apollo.watchQuery<users>({
      query: FETCH_ORGANISATION_ADMIN_BY_ID,
      variables: {
        companyId: orgId,
        first: pageSize,
        hasRoles: ROLES.ORGANISATION_ADMIN,
        page: pageNumber,
        orderBy: [
          {
            column: column.toUpperCase(),
            order: order.toUpperCase(),
          },
        ],
        filter: filter,
        trashed: 'WITHOUT',
      },
      fetchPolicy: 'no-cache',
    });
    return this.fetchOrganisationAdminsQueryRef.valueChanges.pipe(map(({ data }) => data.users));
  }
  refetchOrganisationAdmins() {
    this.fetchOrganisationAdminsQueryRef.refetch();
  }

  fetchAllAdmins(trashed, pageNumber, pageSize, column, order, filter): Observable<OrganisationAdminInfo> {
    this.fetchAllAdminsRef = this.apollo.watchQuery<any>({
      query: FETCH_ORGANISATION_ADMIN_BY_ID,
      variables: {
        first: pageSize,
        hasRoles: ROLES.ORGANISATION_ADMIN,
        page: pageNumber,
        trashed: trashed,
        orderBy: [
          {
            column: column.toUpperCase(),
            order: order.toUpperCase(),
          },
        ],
        filter: filter,
      },
      fetchPolicy: 'no-cache',
    });
    return this.fetchAllAdminsRef.valueChanges.pipe(map(({ data }) => data.users));
  }
  refetchAllAdmins() {
    this.fetchAllAdminsRef.refetch();
  }
  fetchUserById(userId): Observable<User> {
    this.fetchUserByIdRef = this.apollo.watchQuery<any>({
      query: FETCH_USER_BY_ID,
      variables: {
        id: userId,
      },
    });
    return this.fetchUserByIdRef.valueChanges.pipe(map(({ data }) => data.userById));
  }
  refetchUserById() {
    this.fetchUserByIdRef.refetch();
  }
  updateUser(updateUserInput) {
    return this.apollo.mutate<User>({
      mutation: UPDATE_ORGANISATION_ADMIN,
      variables: { input: updateUserInput },
    });
  }
  deleteUser(id) {
    return this.apollo.mutate<User>({
      mutation: DELETE_USER_BY_ID,
      variables: { id: id },
    });
  }
  fetchFleetAdmins(pageNumber, pageSize, column, order, filter, trashed) {
    return this.apollo
      .watchQuery<any>({
        query: FETCH_ORGANISATION_ADMIN_BY_ID,
        variables: {
          first: pageSize,
          hasRoles: ROLES.TCO_FLEET_ADMIN,
          page: pageNumber,
          orderBy: [
            {
              column: column.toUpperCase(),
              order: order.toUpperCase(),
            },
          ],
          filter: filter,
          trashed: trashed,
        },
        fetchPolicy: 'no-cache',
      })
      .valueChanges.pipe(map(({ data }) => data.users));
  }

  upload(file: File, command: UploadCommand = 'CompanyLogoUpload'): Observable<any> {
    // Create form data
    const formData = new FormData();

    // Store form name as "file" with file data
    formData.append('command', command);
    formData.append('Content-Type', file.type);
    formData.append('file', file);

    // Make http post request over api
    // with formData as req
    return this.http.post(environment.uploadUrl, formData);
  }
}
