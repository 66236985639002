import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommonService } from 'app/shared/common.service';
import { UserEditDialogComponent } from '../user-edit-dialog/user-edit-dialog.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Country } from 'app/Models/country';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-service-provider-edit-dialog',
  templateUrl: './service-provider-edit-dialog.component.html',
  styleUrls: ['./service-provider-edit-dialog.component.scss'],
})
export class ServiceProviderEditDialogComponent {
  userForm: FormGroup;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  uploadImage;
  public countries: Country[] = [];
  constructor(
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private _snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<ServiceProviderEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private sanitizer: DomSanitizer,
  ) {}

  ngOnInit(): void {
    // this.commonService.fetchCountries().subscribe((contryDetails) => {
    //     this.countries =contryDetails?.countries;
    // })
    this.countries = this.data.countryInfo.countires;
    this.userForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.email, Validators.required]],
      phone: ['', [Validators.required]],
      address: [''],
      postalCode: [''],
      city: [''],
      state: [''],
      country: ['', Validators.required],
      logo: [''],
      isCarAssetProvider: new FormControl(false),
      isFuelCardAssetProvider: new FormControl(false),
      isBicycleAssetProvider: new FormControl(false),
      isChargingSystemAssetProvider: new FormControl(false),
    });
    // setTimeout(() => {
    this.setFormData(this.data?.serviceProviderInfo);
    // }, 1000);
  }
  setFormData(data) {
    this.croppedImage = data.logo;
    this.userForm.setValue({
      name: data?.name,
      email: data?.email,
      phone: data?.phone,
      address: data?.address,
      postalCode: data?.postalCode,
      city: data?.city,
      state: data?.state,
      country: data?.country?.name,
      logo: data?.logo,
      isCarAssetProvider: data?.isCarAssetProvider,
      isFuelCardAssetProvider: data?.isFuelCardAssetProvider,
      isBicycleAssetProvider: data?.isBicycleAssetProvider,
      isChargingSystemAssetProvider: data?.isChargingSystemAssetProvider,
    });
  }
  updateServiceProvider() {
    let countryDetails = this.data.countryInfo.countries.find(country => country.name === this.userForm.value.country);
    if (this.uploadImage) {
      let updateUserInput = {
        id: this.data?.serviceProviderInfo.id,
        name: this.userForm.value.name,
        email: this.userForm.value.email,
        phone: this.userForm.value.phone,
        address: this.userForm.value.address,
        postalCode: this.userForm.value.postalCode,
        city: this.userForm.value.city,
        state: this.userForm.value.state,
        country: countryDetails.id,
        logo: '',
        isCarAssetProvider: this.userForm.value.isCarAssetProvider,
        isFuelCardAssetProvider: this.userForm.value.isFuelCardAssetProvider,
        isBicycleAssetProvider: this.userForm.value.isBicycleAssetProvider,
        isChargingSystemAssetProvider: this.userForm.value.isChargingSystemAssetProvider,
      };
      this.onUpload().subscribe({
        next: data => {
          updateUserInput.logo = data.path;
          this.commonService.updateServiceProvider(updateUserInput).subscribe({
            next: data => {
              this._snackBar.open('Service Provider updated successfully.', 'close', {
                horizontalPosition: 'right',
                verticalPosition: 'top',
                duration: 1500,
              });
              this.dialogRef.close(data);
            },
            error: error => {
              console.log('Error while update service provider:', error);
              this._snackBar.open('Failed to update Service Provider.', 'close', {
                horizontalPosition: 'right',
                verticalPosition: 'top',
                duration: 1500,
              });
            },
          });
        },
        error: error => {
          console.log('Error while Upload Logo.', error);
        },
      });
    } else {
      let updateUserInput = {
        id: this.data?.serviceProviderInfo.id,
        name: this.userForm.value.name,
        email: this.userForm.value.email,
        phone: this.userForm.value.phone,
        address: this.userForm.value.address,
        postalCode: this.userForm.value.postalCode,
        city: this.userForm.value.city,
        state: this.userForm.value.state,
        country: countryDetails.id,
        isCarAssetProvider: this.userForm.value.isCarAssetProvider,
        isFuelCardAssetProvider: this.userForm.value.isFuelCardAssetProvider,
        isBicycleAssetProvider: this.userForm.value.isBicycleAssetProvider,
        isChargingSystemAssetProvider: this.userForm.value.isChargingSystemAssetProvider,
      };
      this.commonService.updateServiceProvider(updateUserInput).subscribe({
        next: data => {
          this._snackBar.open('Service Provider updated successfully.', 'close', {
            horizontalPosition: 'right',
            verticalPosition: 'top',
            duration: 1500,
          });
          this.dialogRef.close(data);
        },
        error: error => {
          console.log('Error while update service provider:', error);
          this._snackBar.open('Failed to update Service Provider.', 'close', {
            horizontalPosition: 'right',
            verticalPosition: 'top',
            duration: 1500,
          });
        },
      });
    }
  }
  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = this.sanitizer.bypassSecurityTrustUrl(event.objectUrl);
    this.uploadImage = event.blob;
    // event.blob can be used to upload the cropped image
  }

  onUpload() {
    return this.commonService.upload(this.uploadImage);
  }
  onCloseDialog() {
    this.dialogRef.close();
  }
}
