<mat-list>
  <mat-list-item class="p-0">
    <div class="flex justify-between">
      <div mat-dialog-title class="text-2xl text-primary items-center flex justify-center font-bold">
        Service Provider
      </div>
      <button mat-icon-button class="close-button" [mat-dialog-close]="true" (click)="onCloseDialog()">
        <mat-icon class="close-icon" color="warn">close</mat-icon>
      </button>
    </div>
  </mat-list-item>
  <mat-divider></mat-divider>
</mat-list>
<mat-dialog-content class="mat-typography">
  <form [formGroup]="userForm">
    <div class="grid grid-cols-1 gap-4">
      <mat-list>
        <mat-list-item class="p-0">
          <h1 class="text-2xl text-primary">Logo</h1>
        </mat-list-item>
        <mat-divider></mat-divider>
      </mat-list>
      <div class="text-xl font-medium text-primary mb-1">
        <input type="file" (change)="fileChangeEvent($event)" />
      </div>

      <div class="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 grid-cols-1 gap-4">
        <div *ngIf="this.imageChangedEvent">
          <image-cropper
            [imageChangedEvent]="imageChangedEvent"
            [maintainAspectRatio]="true"
            [aspectRatio]="1 / 1"
            [containWithinAspectRatio]="true"
            format="png"
            (imageCropped)="imageCropped($event)"
          ></image-cropper>
        </div>
        <div *ngIf="croppedImage">
          <img [src]="croppedImage" />
        </div>
      </div>
      <div class="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 grid-cols-1 gap-4">
        <!-- First Name -->
        <div>
          <div class="font-medium text-primary mb-1">Name <span class="text-red-500">*</span></div>
          <mat-form-field class="w-full">
            <input matInput formControlName="name" placeholder="First Name" />
            <mat-error *ngIf="userForm?.get('name').hasError('required')"> Name is required </mat-error>
          </mat-form-field>
        </div>
        <!-- Email -->
        <div>
          <div class="font-medium text-primary mb-1">Email <span class="text-red-500">*</span></div>
          <mat-form-field class="w-full">
            <input matInput type="email" formControlName="email" placeholder="Email" />
          </mat-form-field>
        </div>
        <!--Phone Number -->
        <div>
          <div class="font-medium text-primary mb-1">Phone <span class="text-red-500">*</span></div>
          <mat-form-field class="w-full">
            <input matInput formControlName="phone" placeholder="Phone" />
            <mat-error *ngIf="userForm?.get('phone').hasError('required')"> Phone Nmber is required </mat-error>
          </mat-form-field>
        </div>
        <div>
          <div class="font-medium text-primary mb-1">Address</div>
          <mat-form-field class="w-full">
            <input matInput formControlName="address" />
          </mat-form-field>
        </div>
        <div>
          <div class="font-medium text-primary mb-1">Postal Code</div>
          <mat-form-field class="w-full">
            <input matInput formControlName="postalCode" />
          </mat-form-field>
        </div>
        <div>
          <div class="font-medium text-primary mb-1">City</div>
          <mat-form-field class="w-full">
            <input matInput formControlName="city" />
          </mat-form-field>
        </div>
        <div>
          <div class="font-medium text-primary mb-1">State</div>
          <mat-form-field class="w-full">
            <input matInput formControlName="state" />
          </mat-form-field>
        </div>
        <div>
          <div class="font-medium text-primary mb-1">Country <span class="text-red-500">*</span></div>
          <mat-form-field class="w-full">
            <mat-select placeholder="select Country" formControlName="country">
              <mat-option
                value="option"
                *ngFor="let country of this.data.countryInfo.countries"
                [value]="country.name"
                >{{ country.name }}</mat-option
              >
            </mat-select>
            <mat-error *ngIf="userForm?.get('country').hasError('required')"> country is required </mat-error>
          </mat-form-field>
        </div>
      </div>
      <mat-list>
        <mat-list-item class="p-0">
          <h1 class="text-2xl text-primary">Asset Types</h1>
        </mat-list-item>
        <mat-divider></mat-divider>
      </mat-list>
      <div class="grid grid-cols-1 gap-4">
        <div class="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 grid-cols-1 gap-4">
          <div>
            <mat-checkbox class="custom-checkbox" [color]="'primary'" formControlName="isCarAssetProvider"
              >Car Assets</mat-checkbox
            >
          </div>
          <div>
            <mat-checkbox class="custom-checkbox" [color]="'primary'" formControlName="isFuelCardAssetProvider"
              >Fuel Card Assets</mat-checkbox
            >
          </div>
          <div>
            <mat-checkbox class="custom-checkbox" [color]="'primary'" formControlName="isBicycleAssetProvider"
              >Bicycle Assets</mat-checkbox
            >
          </div>
          <div>
            <mat-checkbox class="custom-checkbox" [color]="'primary'" formControlName="isChargingSystemAssetProvider"
              >Charging System Assets
            </mat-checkbox>
          </div>
        </div>
      </div>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button
    mat-raised-button
    class="capitalize"
    color="primary"
    cdkFocusInitial
    [disabled]="userForm.invalid"
    (click)="updateServiceProvider()"
  >
    update service Provider
  </button>
</mat-dialog-actions>
