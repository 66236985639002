<mat-list>
  <mat-list-item class="p-0">
    <div class="flex justify-between">
      <div mat-dialog-title class="text-2xl text-primary items-center flex justify-center font-bold">
        Create TCO Fleet Admin
      </div>
      <button mat-icon-button class="close-button" [mat-dialog-close]="true" (click)="onCloseDialog()">
        <mat-icon class="close-icon" color="warn">close</mat-icon>
      </button>
    </div>
  </mat-list-item>
  <mat-divider></mat-divider>
</mat-list>
<mat-dialog-content class="mat-typography">
  <form [formGroup]="userForm">
    <div class="grid grid-cols-1 gap-4">
      <div class="grid grid-cols-1 gap-4">
        <!-- First Name -->
        <div>
          <div class="font-medium text-primary mb-1">First Name <span class="text-red-500">*</span></div>
          <mat-form-field class="w-full">
            <input matInput formControlName="firstName" placeholder="First Name" />
            <mat-error *ngIf="userForm?.get('firstName').hasError('required')"> First Name is required </mat-error>
          </mat-form-field>
        </div>
        <!-- Last Name -->
        <div>
          <div class="font-medium text-primary mb-1">Last Name <span class="text-red-500">*</span></div>
          <mat-form-field class="w-full">
            <input matInput formControlName="lastName" placeholder="Last Name" />
            <mat-error *ngIf="userForm?.get('lastName').hasError('required')"> Last Name is required </mat-error>
          </mat-form-field>
        </div>
        <!-- Email -->
        <div>
          <div class="font-medium text-primary mb-1">Email <span class="text-red-500">*</span></div>
          <mat-form-field class="w-full">
            <input matInput type="email" formControlName="email" placeholder="Email" />
            <mat-error *ngIf="userForm?.get('email').hasError('email')"> Invalid email format. </mat-error>
            <mat-error *ngIf="userForm?.get('email').hasError('required')"> Email is required </mat-error>
          </mat-form-field>
        </div>
        <!--Phone Number -->
        <div>
          <div class="font-medium text-primary mb-1">Phone <span class="text-red-500">*</span></div>
          <mat-form-field class="w-full">
            <input matInput formControlName="phone" placeholder="Phone" />
            <mat-error *ngIf="userForm?.get('phone').hasError('required')"> Phone Nmber is required </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button
    mat-raised-button
    class="capitalize"
    color="primary"
    [mat-dialog-close]="true"
    cdkFocusInitial
    [disabled]="userForm.invalid"
    (click)="addFleetAdmin()"
  >
    create TCO Fleet Admin
  </button>
</mat-dialog-actions>
