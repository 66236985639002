/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
  {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'basic',
    icon: 'heroicons_outline:chart-pie',
    link: '/dashboard',
  },
  {
    id: 'organisations',
    title: 'Organisations',
    type: 'basic',
    icon: 'heroicons_outline:user-group',
    link: '/organisations',
  },
  {
    id: 'serviceProvider',
    title: 'Service Providers',
    type: 'basic',
    icon: 'heroicons_outline:globe-alt',
    link: '/serviceproviders',
  },
  {
    id: 'users',
    title: 'Organisation Admins',
    type: 'basic',
    icon: 'heroicons_outline:user',
    link: '/users',
  },
  {
    id: 'fleetAdmin',
    title: 'TCO Fleet Admins',
    type: 'basic',
    icon: 'heroicons_outline:chart-pie',
    link: '/tcofleetadmins',
  },
  {
    id: 'carModelInfo',
    title: 'Car Model Info',
    type: 'basic',
    icon: 'mat_outline:directions_car',
    link: '/carmodelinfo',
  },
];
export const compactNavigation: FuseNavigationItem[] = [
  {
    id: 'example',
    title: 'Example',
    type: 'basic',
    icon: 'heroicons_outline:chart-pie',
    link: '/example',
  },
];
export const futuristicNavigation: FuseNavigationItem[] = [
  {
    id: 'example',
    title: 'Example',
    type: 'basic',
    icon: 'heroicons_outline:chart-pie',
    link: '/example',
  },
];
export const horizontalNavigation: FuseNavigationItem[] = [
  {
    id: 'example',
    title: 'Example',
    type: 'basic',
    icon: 'heroicons_outline:chart-pie',
    link: '/example',
  },
];
