<div class="flex flex-col flex-auto min-w-0">
  <!-- Main -->
  <div class="flex-auto p-6 sm:p-10">
    <!-- CONTENT GOES HERE -->
    <div class="h-180 min-h-180 max-h-180">
      <div class="flex flex-col mx-6 mt-3 rounded-md">
        <div class="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 grid-cols-1 gap-4">
          <div>
            <button mat-raised-button class="capitalize">
              <input matInput [formControl]="searchKeywordFilter" placeholder="search" #input />
            </button>
          </div>
          <div class="flex justify-end">
            <button mat-raised-button class="capitalize" color="primary" (click)="openDialog()">
              <mat-icon svgIcon="heroicons_outline:user-group"></mat-icon>
              <span class="ml-2">Create Service Provider</span>
            </button>
          </div>
        </div>
      </div>
      <div class="grid grid-cols-1 m-6 overflow-x-scroll rounded-lg">
        <table
          mat-table
          [dataSource]="dataSource"
          class="mat-elevation-z8"
          matSortActive="NAME"
          matSortDisableClear
          matSortDirection="desc"
          matSort
          #empTbSort="matSort"
        >
          <!--- Note that these columns can be defined in any order.
                          The actual rendered columns are set as a property on the row definition" -->

          <!-- logo Column -->
          <ng-container matColumnDef="logo">
            <th mat-header-cell *matHeaderCellDef>logo</th>
            <td mat-cell *matCellDef="let row" class="border-0">
              <img *ngIf="row.logo" class="rounded-full p-2" src="{{ row.logo }}" />
              <div class="ml-2">
                <ngx-avatar size="35" *ngIf="!row.logo" name="{{ row.name }}"></ngx-avatar>
              </div>
            </td>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef>id</th>
            <td mat-cell *matCellDef="let row" class="border-0">{{ row.id }}</td>
          </ng-container>
          <!-- Name Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>name</th>
            <td mat-cell *matCellDef="let row" class="border-0">{{ row.name }}</td>
          </ng-container>
          <!-- Name Column -->
          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef>Email</th>
            <td mat-cell *matCellDef="let row" class="border-0">{{ row.email }}</td>
          </ng-container>
          <!-- Name Column -->
          <ng-container matColumnDef="telephone">
            <th mat-header-cell *matHeaderCellDef>TelePhone</th>
            <td mat-cell *matCellDef="let row" class="border-0">{{ row.phone }}</td>
          </ng-container>
          <!-- Name Column -->
          <ng-container matColumnDef="car assets">
            <th mat-header-cell *matHeaderCellDef>car assets</th>
            <td mat-cell *matCellDef="let row" class="border-0" [ngSwitch]="row.isCarAssetProvider">
              <mat-icon color="primary" *ngSwitchCase="true" svgIcon="heroicons_outline:check-circle"></mat-icon>
              <mat-icon color="warn" *ngSwitchCase="false" svgIcon="heroicons_outline:x-circle"></mat-icon>
            </td>
          </ng-container>
          <!-- Name Column -->
          <ng-container matColumnDef="fuelcard assets">
            <th mat-header-cell *matHeaderCellDef>Fuel card assets</th>
            <td mat-cell *matCellDef="let row" class="border-0" [ngSwitch]="row.isFuelCardAssetProvider">
              <mat-icon color="primary" *ngSwitchCase="true" svgIcon="heroicons_outline:check-circle"></mat-icon>
              <mat-icon color="warn" *ngSwitchCase="false" svgIcon="heroicons_outline:x-circle"></mat-icon>
            </td>
          </ng-container>

          <!-- Created Column -->
          <ng-container matColumnDef="bicycle assets">
            <th mat-header-cell *matHeaderCellDef>Bicycle assets</th>
            <td mat-cell *matCellDef="let row" class="border-0" [ngSwitch]="row.isBicycleAssetProvider">
              <mat-icon color="primary" *ngSwitchCase="true" svgIcon="heroicons_outline:check-circle"></mat-icon>
              <mat-icon color="warn" *ngSwitchCase="false" svgIcon="heroicons_outline:x-circle"></mat-icon>
            </td>
          </ng-container>
          <!-- Created Column -->
          <ng-container matColumnDef="charging system assets">
            <th mat-header-cell *matHeaderCellDef>charging system assets</th>
            <td mat-cell *matCellDef="let row" class="border-0" [ngSwitch]="row.isChargingSystemAssetProvider">
              <mat-icon color="primary" *ngSwitchCase="true" svgIcon="heroicons_outline:check-circle"></mat-icon>
              <mat-icon color="warn" *ngSwitchCase="false" svgIcon="heroicons_outline:x-circle"></mat-icon>
            </td>
          </ng-container>
          <!-- actions -->
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef class="text-center">Actions</th>

            <mat-cell *matCellDef="let row; let i = index" class="border-0 flex justify-center items-center">
              <button mat-icon-button color="accent" (click)="viewServiceProviderDetail(row.id)">
                <mat-icon svgIcon="heroicons_outline:eye"></mat-icon>
              </button>
              <button mat-icon-button color="primary" (click)="editDialog(i, row)">
                <mat-icon svgIcon="heroicons_outline:pencil"></mat-icon>
              </button>
              <button mat-icon-button color="warn" (click)="deleteServieProvider(row.id)">
                <mat-icon svgIcon="heroicons_outline:trash"></mat-icon>
              </button>
            </mat-cell>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns" class="border-gray-200 border-b-[1px]"></tr>
        </table>
        <mat-paginator
          #paginator
          [length]="totalData"
          [pageSizeOptions]="pageSizes"
          showFirstLastButtons
        ></mat-paginator>
      </div>
    </div>
  </div>
</div>
