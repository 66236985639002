import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Organisation } from 'app/Models/organisation';
import { User } from 'app/Models/user';
import { CommonService } from 'app/shared/common.service';
import { AttachOrganisationDialogComponent } from '../attach-organisation-dialog/attach-organisation-dialog.component';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent {
  currentUserId;
  currentUserDetail: User;
  showDataTable: boolean = false;
  dataSource: MatTableDataSource<Organisation>;
  displayedColumns: string[] = ['logo', 'name', 'created', 'actions'];
  constructor(
    private commonService: CommonService,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
  ) {
    this.dataSource = new MatTableDataSource<Organisation>([]);
  }
  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      const id = params.get('userId');
      this.currentUserId = id;
      if (this.currentUserId) {
        this.commonService.fetchUserById(this.currentUserId).subscribe({
          next: data => {
            this.currentUserDetail = data;
            if (data.organisation) {
              this.showDataTable = true;
              this.dataSource.data = [data.organisation];
            }
          },
          error: error => {
            console.log('Failed to fetch userBy Id', error);
          },
        });
      }
    });
  }

  openDialog() {
    const dialogRef = this.dialog.open(AttachOrganisationDialogComponent, {
      disableClose: true,
      panelClass: 'full-screen-modal',
      data: { currentUser: this.currentUserDetail },
    });

    dialogRef.afterClosed().subscribe(result => {
      this.commonService.refetchUserById();
      this.commonService.fetchUserById(this.currentUserId).subscribe({
        next: data => {
          this.currentUserDetail = data;
          if (data.organisation) {
            this.showDataTable = true;
            this.dataSource.data = [data.organisation];
          }
        },
        error: error => {
          console.log('Failed to fetch userBy Id', error);
        },
      });
    });
  }
  viewOrganisationDetail(orgId) {
    this.router.navigate(['/organisation', orgId]);
  }
  editOrganisationAttach(orgId) {}
  detachOrganisation(orgId) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: true,
      data: { actionType: 'detachOrganisationAdmin', id: this.currentUserId, orgId: orgId },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.commonService.refetchUserById();
        this.commonService.fetchUserById(this.currentUserId).subscribe({
          next: data => {
            this.currentUserDetail = data;
            if (data.organisation) {
              this.showDataTable = true;
              this.dataSource.data = [data.organisation];
            } else {
              this.showDataTable = false;
            }
          },
          error: error => {
            console.log('Failed to fetch userBy Id', error);
          },
        });
      }
    });
  }
}
