<mat-list>
  <mat-list-item class="p-0">
    <div class="flex justify-between">
      <div mat-dialog-title class="text-2xl text-primary items-center flex justify-center font-bold">
        Create Service Provider
      </div>
      <button mat-icon-button class="close-button" [mat-dialog-close]="true" (click)="onCloseDialog()">
        <mat-icon class="close-icon" color="warn">close</mat-icon>
      </button>
    </div>
  </mat-list-item>
  <mat-divider></mat-divider>
</mat-list>
<mat-dialog-content class="mat-typography">
  <form [formGroup]="serviceProviderForm">
    <mat-list>
      <mat-list-item class="p-0">
        <h1 class="text-2xl text-primary">Logo</h1>
      </mat-list-item>
      <mat-divider></mat-divider>
    </mat-list>
    <div class="text-xl font-medium text-primary mb-1">
      <input type="file" (change)="fileChangeEvent($event)" />
    </div>

    <div
      class="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 grid-cols-1 gap-4"
      *ngIf="this.imageChangedEvent"
    >
      <div>
        <image-cropper
          [imageChangedEvent]="imageChangedEvent"
          [maintainAspectRatio]="true"
          [aspectRatio]="1 / 1"
          [containWithinAspectRatio]="true"
          format="png"
          (imageCropped)="imageCropped($event)"
          (imageLoaded)="imageLoaded()"
          (cropperReady)="cropperReady()"
          (loadImageFailed)="loadImageFailed()"
        ></image-cropper>
      </div>
      <div *ngIf="croppedImage">
        <img [src]="croppedImage" />
      </div>
    </div>
    <mat-list>
      <mat-list-item class="p-0">
        <h1 class="text-2xl text-primary">Detail</h1>
      </mat-list-item>
      <mat-divider></mat-divider>
    </mat-list>
    <div class="grid grid-cols-1 gap-4">
      <div class="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 grid-cols-1 gap-4">
        <!-- ServiceProvider Name -->
        <div>
          <div class="font-medium text-primary mb-1">Name <span class="text-red-500">*</span></div>
          <mat-form-field class="w-full">
            <input matInput formControlName="name" placeholder="ServiceProvider Name" />
            <mat-error *ngIf="serviceProviderForm?.get('name').hasError('required')">
              serviceProvider Name is required
            </mat-error>
          </mat-form-field>
        </div>
        <!-- Email -->
        <div>
          <div class="font-medium text-primary mb-1">Email <span class="text-red-500">*</span></div>
          <mat-form-field class="w-full">
            <input matInput formControlName="email" placeholder="Email" />
            <mat-error *ngIf="serviceProviderForm?.get('email').hasError('required')"> Email is required. </mat-error>
            <mat-error *ngIf="serviceProviderForm?.get('email').hasError('email')"> Invalid Email format. </mat-error>
          </mat-form-field>
        </div>
        <!-- Telephone Number -->
        <div>
          <div class="font-medium text-primary mb-1">TelePhone <span class="text-red-500">*</span></div>
          <mat-form-field class="w-full">
            <input matInput formControlName="phone" placeholder="Telephone Number" />
            <mat-error *ngIf="serviceProviderForm?.get('phone').hasError('required')">
              Telephone Number is required
            </mat-error>
          </mat-form-field>
        </div>
        <!-- Address -->
        <div>
          <div class="font-medium text-primary mb-1">Address</div>
          <mat-form-field class="w-full">
            <input matInput formControlName="address" placeholder="Address" />
          </mat-form-field>
        </div>
        <!-- postal Code -->
        <div>
          <div class="font-medium text-primary mb-1">Postal Code</div>
          <mat-form-field class="w-full">
            <input matInput formControlName="postalCode" placeholder="postalCode" />
          </mat-form-field>
        </div>
        <!-- city -->
        <div>
          <div class="font-medium text-primary mb-1">City</div>
          <mat-form-field class="w-full">
            <input matInput formControlName="city" placeholder="city" />
          </mat-form-field>
        </div>
        <!-- state -->
        <div>
          <div class="font-medium text-primary mb-1">State</div>
          <mat-form-field class="w-full">
            <input matInput formControlName="state" placeholder="State" />
          </mat-form-field>
        </div>
        <!-- country -->
        <div>
          <div class="font-medium text-primary mb-1">Country <span class="text-red-500">*</span></div>
          <mat-form-field class="w-full">
            <mat-select placeholder="select Country" formControlName="country">
              <mat-option value="option" *ngFor="let item of countries" [value]="item">{{ item?.name }}</mat-option>
            </mat-select>
            <mat-error *ngIf="serviceProviderForm?.get('country').hasError('required')">
              country is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
    <mat-list>
      <mat-list-item class="p-0">
        <h1 class="text-2xl text-primary">Asset Types</h1>
      </mat-list-item>
      <mat-divider></mat-divider>
    </mat-list>
    <div class="grid grid-cols-1 gap-4">
      <div class="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 grid-cols-1 gap-4">
        <div>
          <mat-checkbox formControlName="isCarAssetProvider" [color]="'primary'">Car Assets</mat-checkbox>
        </div>
        <div>
          <mat-checkbox formControlName="isFuelCardAssetProvider" [color]="'primary'">Fuel Card Assets</mat-checkbox>
        </div>
        <div>
          <mat-checkbox formControlName="isBicycleAssetProvider" [color]="'primary'">Bicycle Assets</mat-checkbox>
        </div>
        <div>
          <mat-checkbox formControlName="isChargingSystemAssetProvider" [color]="'primary'"
            >Charging System Assets
          </mat-checkbox>
        </div>
      </div>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button
    mat-raised-button
    class="capitalize"
    color="primary"
    [disabled]="serviceProviderForm.invalid"
    (click)="submit()"
  >
    Create Service Provider
  </button>
</mat-dialog-actions>
