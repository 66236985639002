import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommonService } from 'app/shared/common.service';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent {
  constructor(
    private dialogRef: MatDialogRef<ConfirmDialogComponent>,
    private _snackBar: MatSnackBar,
    private commonService: CommonService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  /**
   * This function deletes an organization and displays a success message using a snackbar.
   * @param id - The ID of the organization that needs to be deleted.
   */
  deleteOrganisation(id) {
    this.commonService.deleteOrganisation(id).subscribe({
      next: ({ data }) => {
        this._snackBar.open('Organisation deleted successfully.', 'close', {
          horizontalPosition: 'right',
          verticalPosition: 'top',
          duration: 1500,
        });
        this.dialogRef.close(data);
      },
      error: error => {
        console.log('Failed to delete organisation.', error);
        this._snackBar.open('Failed to delete Organisation.', 'close', {
          horizontalPosition: 'right',
          verticalPosition: 'top',
          duration: 1500,
        });
      },
    });
  }
  deleteUser(id) {
    this.commonService.deleteUser(id).subscribe({
      next: deletedUser => {
        this._snackBar.open('user deleted successfully.', 'close', {
          horizontalPosition: 'right',
          verticalPosition: 'top',
          duration: 1500,
        });
        this.dialogRef.close(deletedUser);
      },
      error: error => {
        console.log('Error in delete user:', error);
        this._snackBar.open('Failed to delte user.', 'close', {
          horizontalPosition: 'right',
          verticalPosition: 'top',
          duration: 1500,
        });
      },
    });
  }
  detachAdminUserFromOrganisation(id) {
    let detachAdminInput = {
      id: id,
      organisation: {
        disconnect: this.data?.orgId,
      },
    };
    this.commonService.updateUser(detachAdminInput).subscribe({
      next: data => {
        this._snackBar.open('detach admin from organisation successfully.', 'close', {
          horizontalPosition: 'right',
          verticalPosition: 'top',
          duration: 1500,
        });
        this.dialogRef.close(data);
      },
      error: error => {
        console.log('Error while update user:', error);
        this._snackBar.open('Failed to detach admin from organisation', 'close', {
          horizontalPosition: 'right',
          verticalPosition: 'top',
          duration: 1500,
        });
      },
    });
  }
  deleteServiceProvider(providerId) {
    this.commonService.deleteServiceProvider(providerId).subscribe({
      next: deletedUser => {
        this._snackBar.open('Service Provider deleted successfully.', 'close', {
          horizontalPosition: 'right',
          verticalPosition: 'top',
          duration: 1500,
        });
        this.dialogRef.close(deletedUser);
      },
      error: error => {
        console.log('Error in delete service Provider', error);
        this._snackBar.open(error.message, 'close', {
          horizontalPosition: 'right',
          verticalPosition: 'top',
          duration: 1500,
        });
      },
    });
    this.dialogRef.close();
  }
  deleteTCOFleetAdmin(fleetAdminId) {
    this.commonService.deleteUser(fleetAdminId).subscribe({
      next: deletedUser => {
        this._snackBar.open('TCO Fleet admin deleted successfully.', 'close', {
          horizontalPosition: 'right',
          verticalPosition: 'top',
          duration: 1500,
        });
        this.dialogRef.close(deletedUser);
      },
      error: error => {
        console.log('Error in delete TCO Fleet Admin:', error);
        this._snackBar.open('Failed to delete TCO Fleet admin.', 'close', {
          horizontalPosition: 'right',
          verticalPosition: 'top',
          duration: 1500,
        });
        this.dialogRef.close(error);
      },
    });
  }
}
