import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Organisation } from 'app/Models/organisation';
import { CommonService } from 'app/shared/common.service';
import { ROLES } from 'app/shared/roles.enum';

@Component({
  selector: 'app-user-dialog',
  templateUrl: './user-dialog.component.html',
  styleUrls: ['./user-dialog.component.scss'],
})
export class UserDialogComponent {
  userForm: FormGroup;
  orgList: Organisation[] = [];
  orgRoleId;

  constructor(
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private _snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<UserDialogComponent>,
  ) {}

  ngOnInit(): void {
    this.commonService.fetchRole().subscribe(data => {
      let role = data.find(role => role.slug == ROLES.ORGANISATION_ADMIN);
      this.orgRoleId = role?.id;
    });
    this.userForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.email, Validators.required]],
      phone: ['', Validators.required],
      attachOrgnisation: ['', Validators.required],
    });
    this.commonService.fetchOrganisations(1, 1000, 'name', 'ASC', '').subscribe({
      next: organisations => {
        this.orgList = organisations.data;
      },
      error: error => {
        console.log('Failed to fetch organisations,', error);
      },
    });
  }
  addUser() {
    let userInput = {
      firstName: this.userForm.value.firstName,
      lastName: this.userForm.value.lastName,
      email: this.userForm.value.email,
      phoneNumber: this.userForm.value.phone,
      roles: { connect: [this.orgRoleId] },
      organisation: { connect: this.userForm.value.attachOrgnisation?.id },
    };
    this.commonService.createOrganisationAdmin(userInput).subscribe({
      next: userResponse => {
        this._snackBar.open('Organisation admin created successfully.', 'close', {
          horizontalPosition: 'right',
          verticalPosition: 'top',
          duration: 1500,
        });
        this.dialogRef.close(userResponse);
      },
      error: error => {
        console.log('Error while create Organisation Admin.', error);
        this._snackBar.open('Failed to create organisation admin.', 'close', {
          horizontalPosition: 'right',
          verticalPosition: 'top',
          duration: 1500,
        });
      },
    });
  }
  onCloseDialog() {
    this.dialogRef.close(this.userForm);
  }
}
