import { Component, Inject, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { Country } from 'app/Models/country';
import { CommonService } from 'app/shared/common.service';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
  selector: 'app-service-provider-dialog',
  templateUrl: './service-provider-dialog.component.html',
  styleUrls: ['./service-provider-dialog.component.scss'],
})
export class ServiceProviderDialogComponent {
  serviceProviderForm: FormGroup;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  uploadImage;
  public countries: Country[] = [];
  constructor(
    private formBuilder: FormBuilder,
    private commonservice: CommonService,
    private _snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<ServiceProviderDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private sanitizer: DomSanitizer,
  ) {}
  ngOnChanges(change: SimpleChanges) {
    this.countries = this.data.countryInfo.countires;
  }
  ngOnInit() {
    this.commonservice.fetchCountries().subscribe(contryDetails => {
      this.countries = contryDetails?.countries;
      // this.countryDetails.regions =contryDetails?.regions;
    });
    this.serviceProviderForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.email, Validators.required]],
      phone: ['', [Validators.required]],
      address: [''],
      postalCode: [''],
      city: [''],
      state: [''],
      country: ['', Validators.required],
      isCarAssetProvider: new FormControl(false),
      isFuelCardAssetProvider: new FormControl(false),
      isBicycleAssetProvider: new FormControl(false),
      isChargingSystemAssetProvider: new FormControl(false),
    });
  }
  submit() {
    let serviceProviderInput = {
      name: this.serviceProviderForm.value.name,
      email: this.serviceProviderForm.value.email,
      phone: this.serviceProviderForm.value.phone,
      address: this.serviceProviderForm.value.address,
      postalCode: this.serviceProviderForm.value.postalCode,
      city: this.serviceProviderForm.value.city,
      state: this.serviceProviderForm.value.state,
      country: this.serviceProviderForm.value.country.id,
      logo: '',
      isCarAssetProvider: this.serviceProviderForm.value.isCarAssetProvider,
      isFuelCardAssetProvider: this.serviceProviderForm.value.isFuelCardAssetProvider,
      isBicycleAssetProvider: this.serviceProviderForm.value.isBicycleAssetProvider,
      isChargingSystemAssetProvider: this.serviceProviderForm.value.isChargingSystemAssetProvider,
    };

    if (this.uploadImage) {
      this.onUpload().subscribe({
        next: data => {
          serviceProviderInput.logo = data.path;
          this.commonservice.addServiceProvider(serviceProviderInput).subscribe({
            next: data => {
              this._snackBar.open('Service provider  created successfully.', 'close', {
                horizontalPosition: 'right',
                verticalPosition: 'top',
                duration: 1500,
              });
              this.dialogRef.close(data);
            },
            error: error => {
              console.log('Error:', error);
              this._snackBar.open('Failed to create Service provider.', 'close', {
                horizontalPosition: 'right',
                verticalPosition: 'top',
                duration: 1500,
              });
            },
          });
        },
        error: () => {
          this._snackBar.open('Failed To create Organisation.', 'close', {
            horizontalPosition: 'right',
            verticalPosition: 'top',
            duration: 1000,
          });
        },
      });
    } else {
      this.commonservice.addServiceProvider(serviceProviderInput).subscribe({
        next: data => {
          this._snackBar.open('Service provider  created successfully.', 'close', {
            horizontalPosition: 'right',
            verticalPosition: 'top',
            duration: 1500,
          });
          this.dialogRef.close(data);
        },
        error: error => {
          console.log('Error:', error);
          this._snackBar.open('Failed to create Service provider.', 'close', {
            horizontalPosition: 'right',
            verticalPosition: 'top',
            duration: 1500,
          });
        },
      });
    }
  }
  onCloseDialog() {
    this.dialogRef.close();
  }
  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = this.sanitizer.bypassSecurityTrustUrl(event.objectUrl);
    this.uploadImage = event.blob;
    // event.blob can be used to upload the cropped image
  }

  onUpload() {
    return this.commonservice.upload(this.uploadImage);
  }
}
