import { Component, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ServiceProvider, ServiceProviderInfo } from 'app/Models/serviceProvider';
import { CommonService } from 'app/shared/common.service';
import { ServiceProviderDialogComponent } from '../service-provider-dialog/service-provider-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ServiceProviderEditDialogComponent } from '../service-provider-edit-dialog/service-provider-edit-dialog.component';
import { Router } from '@angular/router';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, SortDirection } from '@angular/material/sort';
import { map, merge, startWith, switchMap } from 'rxjs';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-service-provider',
  templateUrl: './service-provider.component.html',
  styleUrls: ['./service-provider.component.scss'],
})
export class ServiceProviderComponent {
  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = [
    'logo',
    'id',
    'name',
    'email',
    'telephone',
    'car assets',
    'fuelcard assets',
    'bicycle assets',
    'charging system assets',
    'actions',
  ];
  dataSource: MatTableDataSource<ServiceProvider>;
  searchKeywordFilter = new FormControl();
  countryDetails: any = {};
  totalData;
  lastPage;
  searchValid;
  pageSizes = [5, 10];
  constructor(private commonservice: CommonService, private router: Router, public dialog: MatDialog) {
    this.dataSource = new MatTableDataSource<ServiceProvider>([]);
  }

  ngOnInit(): void {
    this.commonservice.fetchCountries().subscribe(contryDetails => {
      this.countryDetails.countries = contryDetails?.countries;
      this.countryDetails.regions = contryDetails?.regions;
    });
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    this.searchKeywordFilter.valueChanges.subscribe({
      next: () => {
        this.searchValid = this.searchKeywordFilter.value.length >= 3 ? true : false;
        this.paginator.pageIndex = 0;
      },
    });
    merge(this.searchKeywordFilter.valueChanges, this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          var filterValue =
            this.searchKeywordFilter.value == null || !this.searchValid ? '' : this.searchKeywordFilter.value;
          return this.getServiceProvider(
            this.paginator.pageIndex + 1,
            this.paginator.pageSize,
            this.sort.active,
            this.sort.direction,
            filterValue,
          );
        }),
        map((details: ServiceProviderInfo) => {
          if (details == null) return [];
          this.totalData = details?.paginatorInfo?.total;
          this.lastPage = details.paginatorInfo.lastPage;
          return details.data;
        }),
      )
      .subscribe({
        next: serviceProviderData => {
          this.dataSource = new MatTableDataSource(serviceProviderData);
        },
        error: error => {
          console.log('Error while fetch serviceProvider.', error);
        },
      });
  }

  getServiceProvider(pageNumber: Number, pageSize: Number, sort: string, orderBy: SortDirection, filter: string) {
    return this.commonservice.fetchServiceProvider(pageNumber, pageSize, sort, orderBy, filter);
  }
  openDialog() {
    const dialogRef = this.dialog.open(ServiceProviderDialogComponent, {
      disableClose: true,
      panelClass: 'full-screen-modal',
      width: '75vw',
      data: { countryInfo: this.countryDetails },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.paginator.length = this.paginator.length + 1;
        this.paginator.pageIndex = 0;
        this.getServiceProvider(
          this.paginator.pageIndex + 1,
          this.paginator.pageSize,
          this.sort.active,
          this.sort.direction,
          '',
        ).subscribe({
          next: (serviceProviderData: ServiceProviderInfo) => {
            this.dataSource = new MatTableDataSource(serviceProviderData.data);
          },
          error: error => {
            console.log('Error while refetch service Provider Data.', error);
          },
        });
      }
    });
  }
  viewServiceProviderDetail(id) {
    this.router.navigate(['/serviceprovider', id]);
  }
  editDialog(index, serviceProviderDetail) {
    const dialogRef = this.dialog.open(ServiceProviderEditDialogComponent, {
      disableClose: true,
      panelClass: 'full-screen-modal',
      width: '75vw',
      data: { countryInfo: this.countryDetails, serviceProviderInfo: serviceProviderDetail },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getServiceProvider(
          this.paginator.pageIndex + 1,
          this.paginator.pageSize,
          this.sort.active,
          this.sort.direction,
          '',
        ).subscribe({
          next: (serviceProviderData: ServiceProviderInfo) => {
            this.dataSource = new MatTableDataSource(serviceProviderData.data);
          },
          error: error => {
            console.log('Error while refetch service Provider Data.', error);
          },
        });
      }
    });
  }

  deleteServieProvider(providerId) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: true,
      data: { actionType: 'deleteServiceProvider', id: providerId },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.paginator.length = this.paginator.length - 1;
        this.paginator.pageIndex = 0;
        this.getServiceProvider(
          this.paginator.pageIndex + 1,
          this.paginator.pageSize,
          this.sort.active,
          this.sort.direction,
          '',
        ).subscribe({
          next: (serviceProviderData: ServiceProviderInfo) => {
            this.dataSource = new MatTableDataSource(serviceProviderData.data);
          },
          error: error => {
            console.log('Error while refetch service Data.', error);
          },
        });
      }
    });
  }
}
