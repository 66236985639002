import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExtraOptions, PreloadAllModules, RouterModule } from '@angular/router';
import { FuseModule } from '@fuse';
import { FuseConfigModule } from '@fuse/services/config';
import { FuseMockApiModule } from '@fuse/lib/mock-api';
import { CoreModule } from 'app/core/core.module';
import { appConfig } from 'app/core/config/app.config';
import { mockApiServices } from 'app/mock-api';
import { LayoutModule } from 'app/layout/layout.module';
import { AppComponent } from 'app/app.component';
import { appRoutes } from 'app/app.routing';
import { GraphQLModule } from './graphql.module';
import { HttpClientModule } from '@angular/common/http';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { OrganisationsComponent } from './components/organisations/organisations.component';
import { MatDialogModule } from '@angular/material/dialog';
import { AdddialogComponent } from './components/adddialog/adddialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { EditdialogComponent } from './components/editdialog/editdialog.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { OrganisationDetailComponent } from './components/organisation-detail/organisation-detail.component';
import { FuseCardModule } from '@fuse/components/card';
import { AdminListComponent } from './components/admin-list/admin-list.component';
import { UsersComponent } from './components/users/users.component';
import { AttachOrganisationDialogComponent } from './components/attach-organisation-dialog/attach-organisation-dialog.component';
import { UserDialogComponent } from './components/user-dialog/user-dialog.component';
import { UserEditDialogComponent } from './components/user-edit-dialog/user-edit-dialog.component';
import { ServiceProviderComponent } from './components/service-provider/service-provider.component';
import { ServiceProviderDialogComponent } from './components/service-provider-dialog/service-provider-dialog.component';
import { ServiceProviderEditDialogComponent } from './components/service-provider-edit-dialog/service-provider-edit-dialog.component';
import { ServiceProviderDetailComponent } from './components/service-provider-detail/service-provider-detail.component';
import { TcoFleetAdminsComponent } from './components/tco-fleet-admins/tco-fleet-admins.component';
import { TcoFleetAdminDetailComponent } from './components/tco-fleet-admin-detail/tco-fleet-admin-detail.component';
import { TcoFleetAdminEditDialogComponent } from './components/tco-fleet-admin-edit-dialog/tco-fleet-admin-edit-dialog.component';
import { TcoFleetAdminDialogComponent } from './components/tco-fleet-admin-dialog/tco-fleet-admin-dialog.component';
import { ProfileComponent } from './components/profile/profile.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { CarModelInfoComponent } from './components/car-model-info/car-model-info.component';
import { UploadFileDialogComponent } from './components/upload-file-dialog/upload-file-dialog.component';
import { FileDropzoneDirective } from './file-dropzone.directive';
import { AvatarModule } from 'ngx-avatar';

const routerConfig: ExtraOptions = {
  preloadingStrategy: PreloadAllModules,
  scrollPositionRestoration: 'enabled',
};

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    OrganisationsComponent,
    AdddialogComponent,
    EditdialogComponent,
    ConfirmDialogComponent,
    OrganisationDetailComponent,
    AdminListComponent,
    UsersComponent,
    CarModelInfoComponent,
    AttachOrganisationDialogComponent,
    UploadFileDialogComponent,
    UserDialogComponent,
    UserEditDialogComponent,
    ServiceProviderComponent,
    ServiceProviderDialogComponent,
    ServiceProviderEditDialogComponent,
    ServiceProviderDetailComponent,
    TcoFleetAdminsComponent,
    TcoFleetAdminDetailComponent,
    TcoFleetAdminEditDialogComponent,
    TcoFleetAdminDialogComponent,
    ProfileComponent,
    FileDropzoneDirective,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(appRoutes, routerConfig),

    // Fuse, FuseConfig & FuseMockAPI
    FuseModule,
    FuseConfigModule.forRoot(appConfig),
    FuseMockApiModule.forRoot(mockApiServices),

    // Core module of your application
    CoreModule,

    // Layout module of your application
    LayoutModule,

    GraphQLModule,
    MatTableModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSelectModule,
    MatDividerModule,
    MatListModule,
    MatSnackBarModule,
    MatCheckboxModule,
    MatPaginatorModule,
    MatSortModule,
    MatSlideToggleModule,
    FuseCardModule,
    ImageCropperModule,
    HttpClientModule,
    AvatarModule,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
