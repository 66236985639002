import { gql } from 'apollo-angular';
export const SERVICE_PROVIDERS = gql`
  query ServiceProviders(
    $companyId: ID
    $excludeLinked: Boolean
    $filter: String
    $first: Int!
    $isBicycleAssetProvider: Boolean
    $isCarAssetProvider: Boolean
    $isChargingSystemAssetProvider: Boolean
    $isFuelCardAssetProvider: Boolean
    $orderBy: [QueryServiceProvidersOrderByOrderByClause!]
    $page: Int!
  ) {
    serviceProviders(
      companyId: $companyId
      excludeLinked: $excludeLinked
      filter: $filter
      first: $first
      isBicycleAssetProvider: $isBicycleAssetProvider
      isCarAssetProvider: $isCarAssetProvider
      isChargingSystemAssetProvider: $isChargingSystemAssetProvider
      isFuelCardAssetProvider: $isFuelCardAssetProvider
      orderBy: $orderBy
      page: $page
    ) {
      data {
        address
        city
        email
        id
        isBicycleAssetProvider
        isCarAssetProvider
        isChargingSystemAssetProvider
        isFuelCardAssetProvider
        logo
        state
        name
        phone
        postalCode
        country {
          id
          name
          code
        }
      }
      paginatorInfo {
        currentPage
        perPage
        total
      }
    }
  }
`;
export const FETCH_SERVICE_PROVIDER_BY_ID = gql`
  query serviceProviderById($id: ID!) {
    serviceProviderById(id: $id) {
      id
      address
      city
      country {
        id
        name
      }
      email
      isBicycleAssetProvider
      isCarAssetProvider
      isChargingSystemAssetProvider
      isFuelCardAssetProvider
      logo
      name
      phone
      postalCode
      state
    }
  }
`;
export const CREATE_SERVICE_PROVIDER = gql`
  mutation createServiceProvider($input: CreateServiceProviderInput!) {
    createServiceProvider(input: $input) {
      address
      city
      state
      email
      id
      isBicycleAssetProvider
      isCarAssetProvider
      isChargingSystemAssetProvider
      isFuelCardAssetProvider
      logo
      name
      phone
      postalCode
      country {
        id
        name
      }
    }
  }
`;
export const UPDATE_SERVICE_PROVIDER = gql`
  mutation updateServiceProvider($input: UpdateServiceProviderInput!) {
    updateServiceProvider(input: $input) {
      id
    }
  }
`;
export const DELETE_SERVICE_PROVIDER = gql`
  mutation deleteServiceProvider($id: ID!) {
    deleteServiceProvider(id: $id) {
      id
      name
    }
  }
`;
