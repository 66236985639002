import { gql } from 'apollo-angular';
export const COUNTRIES_QUERY = gql`
  query CompanyRelations {
    countries {
      id
      name
    }
    regions {
      name
    }
  }
`;
