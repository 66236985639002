<mat-list>
  <mat-list-item class="p-0">
    <div class="flex justify-between">
      <div mat-dialog-title class="text-2xl text-primary items-center flex justify-center font-bold">
        <span>Import file</span>
      </div>
      <button mat-icon-button class="close-button" [mat-dialog-close]="true" (click)="onCloseDialog()">
        <mat-icon class="close-icon">close</mat-icon>
      </button>
    </div>
  </mat-list-item>
  <mat-divider></mat-divider>
</mat-list>
<mat-dialog-content class="mat-typography">
  <mat-progress-spinner *ngIf="this.isLoading" [diameter]="24" [mode]="'indeterminate'" />
  <div *ngIf="this.error" class="text-red-700 py-4">
    {{ error }}
  </div>
  <div *ngIf="this.state === 'SELECT_FILE'">
    <div class="dropzone border-2 border-dashed" appFileDropzone (filesChangeEmiter)="onFileChange($event)">
      <div class="flex flex-col items-center px-8 py-8">
        <div>
          <mat-icon svgIcon="heroicons_outline:cloud-upload"></mat-icon>
        </div>
        <label for="file" class="text-xl"> Click or drag to select a file </label>
        <input
          type="file"
          name="file"
          id="file"
          (change)="onFileChange($event.target.files)"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          class="hidden"
        />
        <div>
          <span class="text-l text-secondary">Supported formats: .xlsx</span>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="this.state === 'FILE_SELECTED'" class="flex flex-col pt-2">
    <div class="row file-detail" *ngFor="let file of files; let ind = index">
      <div class="text-md text-secondary">
        {{ file.name }}
        <button mat-button class="uppercase text-md text-secondary" color="warn" cdkFocusInitial (click)="deleteFile()">
          <mat-icon svgIcon="heroicons_outline:trash"></mat-icon>
        </button>
      </div>
    </div>
  </div>
  <div *ngIf="this.state === 'LOADING'">Uploading and processing file...</div>
  <div *ngIf="this.state === 'SUCCESS'">
    {{ success }}
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close *ngIf="this.state !== 'SUCCESS'">Cancel</button>
  <button
    *ngIf="this.state !== 'SUCCESS'"
    mat-raised-button
    color="primary"
    cdkFocusInitial
    (click)="onImportClick()"
    [disabled]="this.isLoading || this.files.length === 0"
  >
    <span>Import</span>
  </button>
  <button *ngIf="this.state === 'SUCCESS'" mat-raised-button [mat-dialog-close]="true" color="primary">Close</button>
</mat-dialog-actions>
