import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CommonService } from 'app/shared/common.service';

@Component({
  selector: 'app-upload-file-dialog',
  templateUrl: './upload-file-dialog.component.html',
})
export class UploadFileDialogComponent {
  constructor(
    private commonService: CommonService,
    private dialogRef: MatDialogRef<UploadFileDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  public files: File[] = [];
  public isLoading = false;
  public error: string | null = null;
  public success: string | null = null;

  get state() {
    if (this.success) {
      return 'SUCCESS';
    } else if (this.isLoading) {
      return 'LOADING';
    } else if (this.files.length) {
      return 'FILE_SELECTED';
    }
    return 'SELECT_FILE';
  }

  ngOnInit(): void {}

  onImportClick() {
    this.isLoading = true;
    this.error = null;
    this.success = null;
    this.commonService.upload(this.files[0], 'CarModelInfoUpload').subscribe({
      next: data => {
        this.success = `Car model info updated with ${data.info.added} rows.`;
      },
      error: error => {
        this.error = 'Something went wrong.';
        console.log('Error during car model info upload.', error);
      },
    });
  }
  onCloseDialog() {
    this.dialogRef.close();
  }

  onFileChange(files: FileList) {
    console.log('File change', files);
    this.files = [files[0]];
  }

  deleteFile() {
    this.files = [];
  }
}
