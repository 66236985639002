<div class="flex flex-col flex-auto min-w-0">
  <!-- Main -->
  <div class="flex-auto p-6 sm:p-10">
    <!-- CONTENT GOES HERE -->
    <div>
      <div *ngIf="this.currentUserId == null">
        <app-admin-list [isUserTab]="true"></app-admin-list>
      </div>
      <div *ngIf="this.currentUserId != null">
        <div class="grid grid-cols-1 gap-4 rounded-lg text-primary-500 bg-white m-4 p-4">
          <h1 class="text-3xl break-words">Organisation Admin Details:</h1>
          <div class="flex">
            <div class="flex-none lg:w-36 md:w-36 sm:w-18 w-18">
              <span class="text-xl text-primary-500">ID</span>
            </div>
            <div class="flex-auto w-64 break-all">
              <span class="text-lg text-secondary">{{ currentUserDetail?.id }}</span>
            </div>
          </div>
          <mat-divider></mat-divider>
          <div class="flex">
            <div class="flex-none lg:w-36 md:w-36 sm:w-18 w-18">
              <span class="text-xl text-primary-500">First name</span>
            </div>
            <div class="flex-auto w-64 break-all">
              <span class="text-lg text-secondary">{{ currentUserDetail?.firstName }}</span>
            </div>
          </div>
          <mat-divider></mat-divider>
          <div class="flex">
            <div class="flex-none lg:w-36 md:w-36 sm:w-18 w-18">
              <span class="text-xl text-primary-500">Last name</span>
            </div>
            <div class="flex-auto w-64 break-all">
              <span class="text-lg text-secondary">{{ currentUserDetail?.lastName }}</span>
            </div>
          </div>
          <mat-divider></mat-divider>
          <div class="flex">
            <div class="flex-none lg:w-36 md:w-36 sm:w-18 w-18">
              <span class="text-xl text-primary-500">Email</span>
            </div>
            <div class="flex-auto w-64 break-all">
              <span class="text-lg text-secondary">{{ currentUserDetail?.email }}</span>
            </div>
          </div>
          <mat-divider></mat-divider>
          <div class="flex">
            <div class="flex-none lg:w-36 md:w-36 sm:w-18 w-18">
              <span class="text-xl text-primary-500">Phone</span>
            </div>
            <div class="flex-auto w-64 break-all">
              <span class="text-lg text-secondary">{{ currentUserDetail?.phoneNumber }}</span>
            </div>
          </div>
          <mat-divider></mat-divider>
          <div class="flex">
            <div class="flex-none lg:w-36 md:w-36 sm:w-18 w-18">
              <span class="text-xl text-primary-500">Cognito ID</span>
            </div>
            <div class="flex-auto w-64 break-all">
              <span class="text-lg text-secondary">{{ currentUserDetail?.cognitoId }}</span>
            </div>
          </div>
        </div>
        <div class="grid grid-cols-1 rounded-lg text-primary-500 bg-white m-4 p-4">
          <h1 class="text-3xl break-words">Organisation</h1>
          <div>
            <div class="flex flex-col items-end mx-6 mt-3 rounded-md">
              <div>
                <button mat-raised-button class="capitalize" color="primary" (click)="openDialog()">
                  <mat-icon svgIcon="heroicons_outline:user-group"></mat-icon>
                  <span class="ml-2">Attach Organisation</span>
                </button>
              </div>
            </div>
            <div class="grid grid-cols-1 m-6 overflow-y-scroll rounded-lg">
              <table mat-table [dataSource]="dataSource" *ngIf="showDataTable">
                <!--- Note that these columns can be defined in any order.
                                The actual rendered columns are set as a property on the row definition" -->

                <!-- logo Column -->
                <ng-container matColumnDef="logo">
                  <th mat-header-cell *matHeaderCellDef>logo</th>
                  <td mat-cell *matCellDef="let row" class="border-0">
                    {{ row.logo }}
                  </td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef>name</th>
                  <td mat-cell *matCellDef="let row" class="border-0">
                    {{ row.name }}
                  </td>
                </ng-container>

                <!-- Created Column -->
                <ng-container matColumnDef="created">
                  <th mat-header-cell *matHeaderCellDef>createdAt</th>
                  <td mat-cell *matCellDef="let row" class="border-0">
                    {{ row.createdAt | date : 'medium' }}
                  </td>
                </ng-container>
                <!-- actions -->
                <ng-container matColumnDef="actions">
                  <th mat-header-cell *matHeaderCellDef class="text-center">Actions</th>

                  <mat-cell *matCellDef="let row; let i = index" class="border-0 flex justify-center items-center">
                    <button mat-icon-button color="accent" (click)="viewOrganisationDetail(row.id)">
                      <mat-icon svgIcon="heroicons_outline:eye"></mat-icon>
                    </button>
                    <!-- <button mat-icon-button color="primary" (click)="editOrganisationAttach(row.id)">
                                <mat-icon svgIcon="heroicons_outline:pencil"></mat-icon>
                                </button> -->
                    <button mat-icon-button color="warn" (click)="detachOrganisation(row.id)">
                      <mat-icon svgIcon="heroicons_outline:trash"></mat-icon>
                    </button>
                  </mat-cell>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns" class="border-gray-200 border-b-[1px]"></tr>
              </table>
              <div *ngIf="!showDataTable" class="text-lg text-center">No Organisation Attached</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
