import { gql } from 'apollo-angular';
export const ORGANISATIONS_QUERY = gql`
  query Organozations(
    $first: Int!
    $page: Int!
    $trashed: Trashed!
    $orderBy: [QueryOrganisationOrderByClause!]
    $filter: String
  ) {
    organisations(first: $first, page: $page, trashed: $trashed, orderBy: $orderBy, filter: $filter) {
      data {
        id
        address
        name
        vat
        country {
          id
          name
          code
        }
        postalCode
        city
        state
        settings {
          orderModule
        }
        regionBe
        createdAt
        isOrganisation
        logoPath
      }
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
    }
  }
`;

export const CREATE_ORGANISATION = gql`
  mutation createOrganisation($input: CreateOrganisationInput) {
    createOrganisation(input: $input) {
      address
      city
      country {
        code
        id
        name
      }
      createdAt
      id
      isOrganisation
      logoPath
      name
      postalCode
      regionBe
      settings {
        orderModule
      }
      state
      vat
    }
  }
`;
export const UPDATE_ORGANISATION = gql`
  mutation updateOrganisation($input: UpdateOrganisationInput) {
    updateOrganisation(input: $input) {
      address
      city
      country {
        code
        id
        name
      }
      createdAt
      id
      isOrganisation
      logoPath
      name
      postalCode
      regionBe
      state
      settings {
        orderModule
      }
      vat
    }
  }
`;
export const DELETE_ORGANISATION = gql`
  mutation deleteOrganisation($id: ID!) {
    deleteOrganisation(id: $id) {
      id
      name
    }
  }
`;
export const FETCH_ORGANISATION_BY_ID = gql`
  query organisationById($id: ID!) {
    organisationById(id: $id) {
      id
      logoPath
      name
      vat
      country {
        id
        name
        code
      }
      address
      postalCode
      city
      state
      regionBe
    }
  }
`;
export const FETCH_ORGANISATION_ADMIN_BY_ID = gql`
  query users(
    $companyId: ID
    $filter: String
    $first: Int!
    $hasRoles: [RolesEnum!]
    $orderBy: [QueryUsersOrderByOrderByClause!]
    $page: Int
    $trashed: Trashed
  ) {
    users(
      companyId: $companyId
      filter: $filter
      first: $first
      hasRoles: $hasRoles
      orderBy: $orderBy
      page: $page
      trashed: $trashed
    ) {
      data {
        id
        firstName
        lastName
        email
        phoneNumber
        cognitoId
        organisation {
          id
          isOrganisation
          name
        }
        createdAt
      }
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
    }
  }
`;
