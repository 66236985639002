import { Route } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { InitialDataResolver } from 'app/app.resolvers';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { OrganisationsComponent } from './components/organisations/organisations.component';
import { OrganisationDetailComponent } from './components/organisation-detail/organisation-detail.component';
import { UsersComponent } from './components/users/users.component';
import { ServiceProviderComponent } from './components/service-provider/service-provider.component';
import { ServiceProviderDetailComponent } from './components/service-provider-detail/service-provider-detail.component';
import { TcoFleetAdminsComponent } from './components/tco-fleet-admins/tco-fleet-admins.component';
import { TcoFleetAdminDetailComponent } from './components/tco-fleet-admin-detail/tco-fleet-admin-detail.component';
import { ProfileComponent } from './components/profile/profile.component';
import { CarModelInfoComponent } from './components/car-model-info/car-model-info.component';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [
  // Redirect empty path to '/example'
  { path: '', pathMatch: 'full', redirectTo: 'dashboard' },

  // Redirect signed-in user to the '/example'
  //
  // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
  // path. Below is another redirection for that path to redirect the user to the desired
  // location. This is a small convenience to keep all main routes together here on this file.
  { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'dashboard' },

  // Auth routes for guests
  {
    path: '',
    canMatch: [NoAuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      {
        path: 'confirmation-required',
        loadChildren: () =>
          import('app/modules/auth/confirmation-required/confirmation-required.module').then(
            m => m.AuthConfirmationRequiredModule,
          ),
      },
      {
        path: 'forgot-password',
        loadChildren: () =>
          import('app/modules/auth/forgot-password/forgot-password.module').then(m => m.AuthForgotPasswordModule),
      },
      {
        path: 'reset-password',
        loadChildren: () =>
          import('app/modules/auth/reset-password/reset-password.module').then(m => m.AuthResetPasswordModule),
      },
      {
        path: 'sign-in',
        loadChildren: () => import('app/modules/auth/sign-in/sign-in.module').then(m => m.AuthSignInModule),
      },
      {
        path: 'sign-up',
        loadChildren: () => import('app/modules/auth/sign-up/sign-up.module').then(m => m.AuthSignUpModule),
      },
    ],
  },

  // Auth routes for authenticated users
  {
    path: '',
    canMatch: [AuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      {
        path: 'sign-out',
        loadChildren: () => import('app/modules/auth/sign-out/sign-out.module').then(m => m.AuthSignOutModule),
      },
      {
        path: 'unlock-session',
        loadChildren: () =>
          import('app/modules/auth/unlock-session/unlock-session.module').then(m => m.AuthUnlockSessionModule),
      },
    ],
  },

  // Landing routes
  {
    path: '',
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      {
        path: 'home',
        loadChildren: () => import('app/modules/landing/home/home.module').then(m => m.LandingHomeModule),
      },
    ],
  },

  // Admin routes
  {
    path: '',
    canMatch: [AuthGuard],
    component: LayoutComponent,
    resolve: {
      initialData: InitialDataResolver,
    },
    children: [
      {
        path: 'example',
        loadChildren: () => import('app/modules/admin/example/example.module').then(m => m.ExampleModule),
      },
      {
        path: 'dashboard',
        component: DashboardComponent,
      },
      {
        path: 'organisations',
        component: OrganisationsComponent,
      },
      {
        path: 'organisation/:orgId',
        component: OrganisationDetailComponent,
      },
      {
        path: 'serviceproviders',
        component: ServiceProviderComponent,
      },
      {
        path: 'serviceprovider/:providerId',
        component: ServiceProviderDetailComponent,
      },
      {
        path: 'tcofleetadmins',
        component: TcoFleetAdminsComponent,
      },
      {
        path: 'fleetadmin/:fleetAdminId',
        component: TcoFleetAdminDetailComponent,
      },
      {
        path: 'profile',
        component: ProfileComponent,
      },
      {
        path: 'users',
        component: UsersComponent,
      },
      {
        path: 'users/:userId',
        component: UsersComponent,
      },
      {
        path: 'carmodelinfo',
        component: CarModelInfoComponent,
      },
      { path: '**', redirectTo: '/dashboard', pathMatch: 'full' },
    ],
  },
];
