import { Component, Input, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { OrganisationAdminInfo, User } from 'app/Models/user';
import { CommonService } from 'app/shared/common.service';
import { AdddialogComponent } from '../adddialog/adddialog.component';
import { ActivatedRoute, Router } from '@angular/router';
import { TRASHED } from 'app/shared/enum.trashed';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { UserDialogComponent } from '../user-dialog/user-dialog.component';
import { UserEditDialogComponent } from '../user-edit-dialog/user-edit-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatPaginator } from '@angular/material/paginator';
import { catchError, map, startWith, switchMap, merge } from 'rxjs';
import { MatSort, SortDirection } from '@angular/material/sort';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-admin-list',
  templateUrl: './admin-list.component.html',
  styleUrls: ['./admin-list.component.scss'],
})
export class AdminListComponent {
  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @Input() organisation;
  @Input() isUserTab;
  displayedColumns: string[] = ['FIRST_NAME', 'LAST_NAME', 'email', 'phoneNumber', 'cognitoId', 'actions'];
  dataSource: MatTableDataSource<User>;
  searchKeywordFilter = new FormControl();
  pageSizes = [5, 10];
  totalData;
  lastPage;
  orgId;
  searchValid;
  isOrganisationById: Boolean = false;
  constructor(
    private commonService: CommonService,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.dataSource = new MatTableDataSource<User>([]);
  }
  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      const id = params.get('orgId');
      this.orgId = id;
    });
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    this.searchKeywordFilter.valueChanges.subscribe({
      next: () => {
        this.searchValid = this.searchKeywordFilter.value.length >= 3 ? true : false;
        this.paginator.pageIndex = 0;
      },
    });
    merge(this.searchKeywordFilter.valueChanges, this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          var filterValue =
            this.searchKeywordFilter.value == null || !this.searchValid ? '' : this.searchKeywordFilter.value;
          if (this.organisation) {
            return this.getOrganisationAdminData(
              this.paginator.pageIndex + 1,
              this.paginator.pageSize,
              this.sort.active,
              this.sort.direction,
              filterValue,
            ).pipe(catchError(async error => console.log('Error fetch Organisation Admin', error)));
          } else if (this.orgId) {
            return this.commonService
              .fetchOrganisationAdmins(
                this.orgId,
                this.paginator.pageIndex + 1,
                this.paginator.pageSize,
                this.sort.active,
                this.sort.direction,
                filterValue,
              )
              .pipe(catchError(async error => console.log('Error fetch Organisation Admin', error)));
          } else {
            return this.getfetchAllAdmins(
              this.paginator.pageIndex + 1,
              this.paginator.pageSize,
              this.sort.active,
              this.sort.direction,
              filterValue,
            ).pipe(catchError(async error => console.log('Error fetch AllAdmins.', error)));
          }
        }),
        map((details: OrganisationAdminInfo) => {
          if (details == null) return [];
          this.totalData = details.paginatorInfo.total;
          this.lastPage = details.paginatorInfo.lastPage;
          return details.data;
        }),
      )
      .subscribe(details => {
        this.dataSource = new MatTableDataSource(details);
      });
  }
  getOrganisationAdminData(pageNumber: Number, pageSize: Number, sort: string, orderBy: SortDirection, filter: string) {
    return this.commonService.fetchOrganisationAdmins(
      this.organisation?.id,
      pageNumber,
      pageSize,
      sort,
      orderBy,
      filter,
    );
  }
  getfetchAllAdmins(pageNumber: Number, pageSize: Number, sort: string, orderBy: SortDirection, filter: string) {
    return this.commonService.fetchAllAdmins(TRASHED.TRASHED_WITHOUT, pageNumber, pageSize, sort, orderBy, filter);
  }
  openDialog() {
    const dialogRef = this.dialog.open(AdddialogComponent, {
      disableClose: true,
      panelClass: 'full-screen-modal',
      data: { org: this.organisation, dialogType: 'attachOrgAdmin' },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.paginator.pageIndex = 0;
        this.getOrganisationAdminData(
          this.paginator.pageIndex + 1,
          this.paginator.pageSize,
          this.sort.active,
          this.sort.direction,
          '',
        )
          .pipe(catchError(async error => console.log('Error Fetch Data:', error)))
          .subscribe({
            next: (orgAdminsData: OrganisationAdminInfo) => {
              this.dataSource = new MatTableDataSource(orgAdminsData.data);
            },
            error: error => {
              console.log('Error while Fetch Data:', error);
            },
          });
      }
    });
  }
  viewOrganisationAdminDetail(userId) {
    this.router.navigate(['/users', userId]);
  }
  detachOrganisation(userId) {
    let isOrganisation;
    this.route.url.subscribe(urlSegments => {
      const keyword = 'organisation';
      isOrganisation = urlSegments.some(segment => segment.path.includes(keyword));
    });
    this.route.paramMap.subscribe(params => {
      let detachAdminInput = {
        id: userId,
        organisation: {
          disconnect: this.organisation?.id,
        },
      };
      const id = params.get('userId');
      if (id) {
        this.commonService.updateUser(detachAdminInput).subscribe({
          next: data => {
            this._snackBar.open('Detach admin from organisation successfully.', 'close', {
              horizontalPosition: 'right',
              verticalPosition: 'top',
              duration: 1500,
            });
            this.paginator.length = this.paginator.length - 1;
          },
          error: error => {
            console.log('Error while update user:', error);
            this._snackBar.open('Failed to detach admin from organisation.', 'close', {
              horizontalPosition: 'right',
              verticalPosition: 'top',
              duration: 1500,
            });
          },
        });
      } else if (isOrganisation) {
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
          disableClose: true,
          data: { actionType: 'detachOrganisationAdmin', id: userId, orgId: this.organisation?.id },
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.paginator.pageIndex = 0;
            this.getOrganisationAdminData(
              this.paginator.pageIndex + 1,
              this.paginator.pageSize,
              this.sort.active,
              this.sort.direction,
              '',
            )
              .pipe(catchError(async error => console.log('Error Fetch Data:', error)))
              .subscribe({
                next: (orgAdminsData: OrganisationAdminInfo) => {
                  this.paginator.length = this.paginator.length - 1;
                  this.dataSource = new MatTableDataSource(orgAdminsData.data);
                },
                error: error => {
                  console.log('Error while Fetch Data:', error);
                },
              });
          }
        });
      } else {
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
          disableClose: true,
          data: { actionType: 'deleteuser', id: userId },
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.paginator.pageIndex = 0;
            this.getfetchAllAdmins(
              this.paginator.pageIndex + 1,
              this.paginator.pageSize,
              this.sort.active,
              this.sort.direction,
              '',
            ).subscribe({
              next: (users: OrganisationAdminInfo) => {
                this.paginator.length = this.paginator.length - 1;
                this.dataSource = new MatTableDataSource(users.data);
              },
              error: error => {
                console.log('Error fetch AllAdmins:', error);
              },
            });
          }
        });
      }
    });
  }
  openUserDialog() {
    const dialogRef = this.dialog.open(UserDialogComponent, {
      disableClose: true,
      panelClass: 'full-screen-modal',
      width: '75vw',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.paginator.pageIndex = 0;
        this.commonService
          .fetchOrganisationAdmins(
            this.orgId,
            this.paginator.pageIndex + 1,
            this.paginator.pageSize,
            this.sort.active,
            this.sort.direction,
            '',
          )
          .subscribe({
            next: (users: OrganisationAdminInfo) => {
              this.paginator.length = this.paginator.length + 1;
              this.dataSource = new MatTableDataSource(users.data);
            },
            error: error => {
              console.log('Error fetch AllAdmins:', error);
            },
          });
      }
    });
  }
  editUser(index, userDetail) {
    const dialogRef = this.dialog.open(UserEditDialogComponent, {
      disableClose: true,
      panelClass: 'full-screen-modal',
      width: '75vw',
      data: userDetail,
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.paginator.pageIndex = 0;
        this.getfetchAllAdmins(
          this.paginator.pageIndex + 1,
          this.paginator.pageSize,
          this.sort.active,
          this.sort.direction,
          '',
        ).subscribe({
          next: (users: OrganisationAdminInfo) => {
            this.dataSource = new MatTableDataSource(users.data);
          },
          error: error => {
            console.log('Error fetch AllAdmins:', error);
          },
        });
      }
    });
  }
}
